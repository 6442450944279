import {
  Header,
  Grid,
  Label,
  Divider,
  Segment,
  Button,
  Icon,
  Message,
  Popup,
  Loader,
  Dropdown,
  Table,
} from "semantic-ui-react";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import Moment from "react-moment";
import { now } from "moment";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "../components/form";
import { useUserAccess } from "../components/UserAccessManager";

import UserDetails from "../components/UserDetails";
import VehicleDetails from "../components/VehicleDetails";
import EditBookingModal from "../components/modals/EditBookingModal";
import { EndBookingModal } from "../components/modals/EndBookingModal";
import BookingOrders from "../components/BookingOrders";
import { useChatActions } from "../components/chat-window-manager/ChatWindowManager";
import { dateTimeFormat } from "../utils/date";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { date, object, ref } from "yup";

const requiredDate = date().required();
const validationSchema = object({
  startTime: requiredDate.label("Start time"),
  endTime: requiredDate
    .min(ref("startTime"), "End time must be after start time")
    .label("End time"),
});

const Booking = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(Math.random());
  const [booking, setBooking] = useState([]);
  const [saveStatus, setSaveStatus] = useState();
  const [isSimpleBookingEditorOpen, setIsSimpleBookingEditorOpen] =
    useState(false);
  const [isBookingEditorOpen, setIsBookingEditorOpen] = useState(false);
  const [isEndBookingModalOpen, setIsEndBookingModalOpen] = useState(false);
  const [endBookingActiveStep, setEndBookingActiveStep] = useState(0);
  const [overlapWarningVisible, setOverlapWarningVisible] = useState(false);

  const navigate = useNavigate();
  const { isCommunityManager } = useUserAccess();
  const { getAccessTokenSilently } = useAuth0();
  const { toggleOpenChatWindow, insertTemplate } = useChatActions();

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    const fetchedBooking = await api.getBookingById(id, accessToken);
    setBooking(fetchedBooking);

    setIsLoading(false);

    const { startTime, endTime } = fetchedBooking;
    return { startTime: new Date(startTime), endTime: new Date(endTime) };
  }, [getAccessTokenSilently, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData, render]);

  const formMethods = useForm({
    defaultValues: fetchData,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setError,
    trigger,
    formState: { isSubmitting, errors },
  } = formMethods;

  const handleSaveClick = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();

      try {
        await api.patchBooking(booking.id, accessToken, data);

        setSaveStatus("success");
        setRender(Math.random());
        setIsSimpleBookingEditorOpen(false);
      } catch (error) {
        setError("root.serverError", {
          type: "500",
        });
      }
    },
    [booking.id, getAccessTokenSilently, setError]
  );

  const handleOverrideSaveClick = useCallback(
    async (requestObject) => {
      const accessToken = await getAccessTokenSilently();

      await api.patchBooking(booking.id, accessToken, requestObject);

      setSaveStatus("success");
      setRender(Math.random());
    },
    [booking.id, getAccessTokenSilently]
  );

  const handleEndBooking = useCallback(
    async (bookingId, requestObject) => {
      const accessToken = await getAccessTokenSilently();

      await api.endBooking(bookingId, requestObject, accessToken);
      setRender(Math.random());
    },
    [getAccessTokenSilently]
  );

  const handleGetMileageBefore = useCallback(
    async (bookingId) => {
      const accessToken = await getAccessTokenSilently();

      const response = await api.getMileageBefore(bookingId, accessToken);
      return response.mileageBefore;
    },
    [getAccessTokenSilently]
  );

  const handleGetMileageAfter = useCallback(
    async (bookingId) => {
      const accessToken = await getAccessTokenSilently();

      const response = await api.getMileageAfter(bookingId, accessToken);
      return response.mileageAfter;
    },
    [getAccessTokenSilently]
  );

  const displayOverdueLabel = useCallback((booking) => {
    if (
      new Date() > new Date(booking.endTime) &&
      booking.status === "ongoing"
    ) {
      return (
        <>
          <Label color="red" content="overdue" icon="clock" /> by{" "}
          <Moment
            format="h[h] m[m]"
            trim
            duration={new Date(booking.endTime)}
            date={new Date(now())}
          />
        </>
      );
    } else return;
  }, []);

  const checkIfBookingsOverlap = useCallback(
    (endTime) => {
      if (
        booking.nextBookingTime !== null &&
        endTime >= new Date(booking.nextBookingTime)
      ) {
        setOverlapWarningVisible(true);
      } else {
        setOverlapWarningVisible(false);
      }
    },
    [booking.nextBookingTime]
  );

  const handleOpenEndBookingModal = useCallback(() => {
    setEndBookingActiveStep(0);
    setIsEndBookingModalOpen(true);
  }, []);

  if (isLoading) {
    return <Loader active size="big" />;
  }

  return (
    <>
      <Grid stackable columns="equal">
        <Grid.Column>
          <Popup
            position="top center"
            on="hover"
            content="Go to user profile"
            trigger={
              <Link to={`../users/${booking.user.id}`}>
                <Header as="h3" textAlign="center">
                  User Details
                </Header>
              </Link>
            }
          />

          <UserDetails user={booking.user} />

          {!isCommunityManager && (
            <Button.Group compact size="small" basic>
              <Button onClick={() => toggleOpenChatWindow(booking.user)}>
                <Icon name="send" />
                SMS
              </Button>
              <Dropdown className="button icon" trigger={<></>}>
                <Dropdown.Menu>
                  <Dropdown.Header
                    content="Templates"
                    // NOTE (Matt): This is a hack to make the text a more readable size.
                    // The value was taken from Semantic UI's styles.
                    style={{ fontSize: ".78571429rem" }}
                  />
                  <Dropdown.Divider />
                  {["Overdue booking", "Late return", "Delayed booking"].map(
                    (item) => (
                      <Dropdown.Item
                        key={item}
                        onClick={() => insertTemplate(item, booking)}
                      >
                        {item}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Button.Group>
          )}
        </Grid.Column>

        <Grid.Column>
          <Popup
            position="top center"
            on="hover"
            content="Go to vehicle profile"
            trigger={
              <Link to={`../vehicles/${booking.vehicle.id}`}>
                <Header as="h3" textAlign="center">
                  Vehicle Details
                </Header>
              </Link>
            }
          />

          <VehicleDetails
            vehicle={booking.vehicle}
            site={booking.vehicle.site}
            compact={true}
          />

          <Divider hidden />

          <Header as="h4">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>Booking Site</Grid.Column>
                <Grid.Column>Booking Etag Number</Grid.Column>
              </Grid.Row>
            </Grid>

            <Header.Subheader>
              <Grid columns={2}>
                <Grid.Row verticalAlign="middle">
                  <Grid.Column>
                    <Popup
                      mouseEnterDelay={500}
                      content="Go to site bookings"
                      position="bottom center"
                      trigger={
                        <Button
                          basic
                          compact
                          icon="arrow right"
                          labelPosition="right"
                          content={booking.site.displayName}
                          onClick={() =>
                            navigate(
                              `/sites/${booking.site.id}/vehiclebookings`
                            )
                          }
                        />
                      }
                    />
                  </Grid.Column>

                  <Grid.Column>
                    {booking.vehicleEtagNumber === null
                      ? "N/A"
                      : booking.vehicleEtagNumber}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>

      <Divider hidden section />
      <Divider hidden section />

      <Divider horizontal>
        <Header as="h2">Booking Details</Header>
      </Divider>

      <Divider hidden section />

      <Grid stackable columns="equal">
        <Grid.Row>
          <Grid.Column width={5}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h4">
                      ID
                      <Header.Subheader>{booking.id}</Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header as="h4">
                      Status
                      <Header.Subheader>
                        <Label
                          className={booking.status}
                          style={{ margin: "2px 0 0 0" }} // Removes left margin and makes spacing consistent with other headers
                        >
                          {booking.status}
                        </Label>
                        {displayOverdueLabel(booking)}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Header as="h4">
                      Next Booking Time
                      <Header.Subheader>
                        {booking.nextBookingTime == null
                          ? "N/A"
                          : dateTimeFormat.format(
                              new Date(booking.nextBookingTime)
                            )}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <FormProvider
              {...formMethods}
              handleSubmit={handleSubmit(handleSaveClick)}
            >
              <Table stackable basic="very">
                <Table.Body>
                  <Table.Row verticalAlign="top">
                    <Table.Cell>
                      <Header as="h4">
                        Start Time
                        <Header.Subheader>
                          {dateTimeFormat.format(new Date(booking.startTime))}
                        </Header.Subheader>
                      </Header>

                      {isSimpleBookingEditorOpen && (
                        <DateTimePicker
                          name="startTime"
                          clearIcon={null}
                          // NOTE (Matt): Circular references are not allowed in the validation schema,
                          // so trigger is required to ensure the error message disappears when this input is changed
                          onChange={() => trigger("endTime")}
                        />
                      )}
                    </Table.Cell>

                    <Table.Cell>
                      <Header as="h4">
                        End Time
                        <Header.Subheader>
                          {dateTimeFormat.format(new Date(booking.endTime))}
                        </Header.Subheader>
                      </Header>

                      {isSimpleBookingEditorOpen && (
                        <>
                          <DateTimePicker
                            name="endTime"
                            clearIcon={null}
                            onChange={checkIfBookingsOverlap}
                          />
                          {overlapWarningVisible && (
                            <Label color="orange" basic pointing="above">
                              <Icon name="exclamation circle" />
                              Warning! May conflict with next booking
                            </Label>
                          )}
                        </>
                      )}
                    </Table.Cell>

                    <Table.Cell width={3}>
                      <Header>
                        <Button
                          basic={isSimpleBookingEditorOpen && true}
                          color="teal"
                          fluid
                          icon={isSimpleBookingEditorOpen ? "close" : "edit"}
                          content={
                            isSimpleBookingEditorOpen ? "Cancel" : "Edit"
                          }
                          onClick={() =>
                            setIsSimpleBookingEditorOpen((prev) => !prev)
                          }
                        />
                      </Header>

                      {isSimpleBookingEditorOpen && (
                        <Button
                          fluid
                          onClick={handleSubmit(handleSaveClick)}
                          color="green"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          // Separates this button from the button above it
                          style={{ marginTop: "8px" }}
                        >
                          <Icon name="check" />
                          Save
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4">
                        Actual Start Time
                        <Header.Subheader>
                          {booking.actualStartTime == null
                            ? "N/A"
                            : dateTimeFormat.format(
                                new Date(booking.actualStartTime)
                              )}
                        </Header.Subheader>
                      </Header>
                    </Table.Cell>

                    <Table.Cell>
                      <Header as="h4">
                        Actual End Time
                        <Header.Subheader>
                          {booking.actualEndTime == null
                            ? "N/A"
                            : dateTimeFormat.format(
                                new Date(booking.actualEndTime)
                              )}
                        </Header.Subheader>
                      </Header>
                    </Table.Cell>

                    <Table.Cell width={2} />
                  </Table.Row>
                </Table.Body>
              </Table>
            </FormProvider>

            <Divider hidden />

            {!isCommunityManager && (
              <>
                <Button
                  basic
                  color="red"
                  onClick={() => setIsBookingEditorOpen(true)}
                >
                  <Icon name="edit" />
                  Manual override
                </Button>

                <Button
                  color="red"
                  disabled={
                    booking.status === "ended" || booking.status === "cancelled"
                  }
                  onClick={handleOpenEndBookingModal}
                >
                  <Icon name="delete calendar" />
                  End booking
                </Button>
              </>
            )}

            {saveStatus === "success" && (
              <Message positive>
                <Message.Header>Save was successful!</Message.Header>
              </Message>
            )}

            {errors.root && (
              <Message error icon>
                <Icon name="times circle" />
                <Message.Content>
                  <Message.Header>Something went wrong</Message.Header>
                  API Error. Contact Ike
                </Message.Content>
              </Message>
            )}

            <EditBookingModal
              isOpen={isBookingEditorOpen}
              onClose={() => setIsBookingEditorOpen(false)}
              booking={booking}
              onSave={handleOverrideSaveClick}
              onEndBooking={(bookingId) => handleEndBooking(bookingId)}
              onGetMileageBefore={() => handleGetMileageBefore(booking.id)}
              onGetMileageAfter={() => handleGetMileageAfter(booking.id)}
            />

            <EndBookingModal
              isOpen={isEndBookingModalOpen}
              onClose={() => setIsEndBookingModalOpen(false)}
              booking={booking}
              onEndBooking={(requestObject) =>
                handleEndBooking(booking.id, requestObject)
              }
              onGetMileageAfter={() => handleGetMileageAfter(booking.id)}
              activeStep={endBookingActiveStep}
              setActiveStep={setEndBookingActiveStep}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden section />

      {!isCommunityManager && <BookingOrders booking={booking} />}
    </>
  );
};

export default Booking;
