import { Table, Button } from 'semantic-ui-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api } from '../api';
import { useAuth0 } from '@auth0/auth0-react';
import { isLoading } from '../utils/isLoading';
import { Loader } from '../components/Loader';

const UsersAtSite = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [siteUsers, setSiteUsers] = useState({state: "loading", value: []});
    
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            const fetchedSiteUsers = await api.getUsersAtSite(id, accessToken)
            setSiteUsers({state: "success", value: fetchedSiteUsers});
        }
        fetchData()
    }, [getAccessTokenSilently, id]);

    return (
        <Loader isLoading={isLoading(siteUsers)}>
            <Button 
                color='blue'
                onClick={() => navigate(`/sites/${id}/assign`)}
            >
                Assign New User
            </Button>
            <Table textAlign='center'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Phone Number</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { siteUsers.value.map(user => 
                        <Table.Row key={user.id}>
                            <Table.Cell>{user.firstName} {user.lastName}</Table.Cell>
                            <Table.Cell>{user.phoneNumber}</Table.Cell>
                            <Table.Cell>{user.email}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

            </Table>
        </Loader>
    );
}

export default UsersAtSite;