import {
  Header,
  Modal,
  Label,
  Button,
  Grid,
  Segment,
  Accordion,
  Form,
  Icon,
  FormButton,
  FormGroup,
  FormRadio,
  Message,
  Container,
  Popup,
} from "semantic-ui-react";
import { api } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import Moment from "react-moment";

const IssuesModal = ({ isOpen, onClose, userId, issues, onSave }) => {
  const [saveStatus, setSaveStatus] = useState({});

  const [accordionIndex, setAccordionIndex] = useState(-1);
  const [newIssueStatus, setNewIssueStatus] = useState("admin");
  const [newIssueMessage, setNewIssueMessage] = useState("");
  const [charCount, setCharCount] = useState(0);

  const { getAccessTokenSilently } = useAuth0();

  const handleAccordionClick = (e, i) => {
    const { index } = i;
    setAccordionIndex(accordionIndex === index ? -1 : index);
    setSaveStatus("");
  };

  const handleFormReset = () => {
    setNewIssueStatus("admin");
    setNewIssueMessage("");
    setAccordionIndex(-1);
    setCharCount(0);
  };

  const handleIssueStatusChange = (value) => {
    const status = value;
    setNewIssueStatus(status);
  };

  const handleIssueTextChange = (e) => {
    const message = e.target.value;
    const count = e.target.value.length;
    setNewIssueMessage(message);
    setCharCount(count);
  };

  const handleFormSubmit = async () => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      message: newIssueMessage,
      status: newIssueStatus,
    };

    await api
      .createNewAdminIssue(userId, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        handleFormReset();
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });

    onSave();
  };

  const handleEditSuspensionIssue = async (issue) => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      status: "admin",
    };

    await api
      .patchIssue(issue.id, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        handleFormReset();
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });

    onSave();
  };

  return (
    <>
      <Modal closeIcon size="tiny" open={isOpen} onClose={onClose}>
        <Modal.Header>Admin Issues</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column>
              <Container textAlign="center">
                {saveStatus.status === "success" && (
                  <Message positive>
                    <Message.Header>Save was Successful!</Message.Header>
                  </Message>
                )}
                {saveStatus.status === "error" && (
                  <Message negative>
                    <Message.Header>
                      Something went wrong - {saveStatus.errorMessage}
                    </Message.Header>
                  </Message>
                )}
              </Container>
              <Accordion>
                <Accordion.Title
                  active={accordionIndex === 0}
                  index={0}
                  onClick={handleAccordionClick}
                >
                  <Grid.Row textAlign="right">
                    <Label as="a" size="large">
                      {accordionIndex === -1 ? (
                        <Icon name="add" />
                      ) : (
                        <Icon name="minus" />
                      )}
                      New issue
                    </Label>
                  </Grid.Row>
                </Accordion.Title>

                <Accordion.Content active={accordionIndex === 0}>
                  <Form onSubmit={handleFormSubmit}>
                    <FormGroup inline>
                      <label> Issue type</label>
                      <FormRadio
                        label="Admin"
                        value="admin"
                        checked={newIssueStatus === "admin"}
                        onChange={(e, data) =>
                          handleIssueStatusChange(data.value)
                        }
                      />
                      <FormRadio
                        label="Suspension"
                        value="suspension"
                        checked={newIssueStatus === "suspension"}
                        onChange={(e, data) =>
                          handleIssueStatusChange(data.value)
                        }
                      />
                    </FormGroup>
                    <textarea
                      maxLength={180}
                      label="Message"
                      placeholder="Description of admin note or reason for suspension"
                      value={newIssueMessage}
                      onChange={(e) => handleIssueTextChange(e)}
                    />
                    {charCount}/180
                    <Container textAlign="right">
                      <FormButton
                        size="small"
                        color="green"
                        icon="check"
                        labelPosition="left"
                        content="Save"
                      />
                    </Container>
                  </Form>
                </Accordion.Content>
              </Accordion>
              {issues.map((issue) => (
                <Segment key={issue.id}>
                  <Grid columns={2}>
                    <Grid.Column width={13}>
                      <Header as="h4">{issue.message}</Header>
                      <div>
                        <Moment format="llll Z" local>
                          {issue.createdAt}
                        </Moment>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      width={3}
                      textAlign="right"
                      verticalAlign="top"
                    >
                      <Label
                        attached="top right"
                        size="small"
                        className={issue.status}
                      >
                        {issue.status}
                      </Label>
                      {issue.status === "suspension" && (
                        <Popup
                          on="hover"
                          content="Remove suspension warning, keeps note"
                          position="left center"
                          trigger={
                            <Button
                              floated="right"
                              icon="close"
                              size="tiny"
                              onClick={() => handleEditSuspensionIssue(issue)}
                            />
                          }
                        />
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
              ))}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default IssuesModal;
