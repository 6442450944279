import { useCallback, useState } from "react";
import { Input, Icon, Button } from "semantic-ui-react";

/**
 * An uncontrolled text input that has the following functionality:
 *
 * - onSubmit is called when the input changes.
 * - The input is cleared when the user clicks the clear button, or presses escape or delete.
 * @param {{
 *  placeholder: string
 *  onSubmit: (inputValue: string) => void
 * }} props
 */
export const TextInput = ({ placeholder, onSubmit, ...props }) => {
  const [inputValue, setInputValue] = useState("");

  const handleClear = useCallback(() => {
    setInputValue("");
    onSubmit("");
  }, [onSubmit]);

  const keyDownHandler = useCallback(
    (keyCode) => {
      if (keyCode === "Escape" || keyCode === "Delete") {
        handleClear();
      }
    },
    [handleClear]
  );

  const handleChange = useCallback(
    (e) => {
      const newSearchValue = e.target.value;
      setInputValue(newSearchValue ?? "");
      onSubmit(newSearchValue ?? "");
    },
    [onSubmit]
  );

  return (
    <Input
      {...props}
      onKeyDown={(key) => keyDownHandler(key.code)}
      icon="search"
      iconPosition="left"
      placeholder={placeholder}
      value={inputValue}
      onChange={handleChange}
      input={<input data-1p-ignore />}
      action={
        <Button type="button" icon onClick={handleClear}>
          <Icon name="close" />
        </Button>
      }
    />
  );
};
