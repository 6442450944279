import { Header, Grid, Image, Segment, Card, Icon, Label, Popup, Container, Button } from 'semantic-ui-react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { useDamageReports } from '../components/DamageReportsContext';

const DamageReportsList = ({ damages, quickDiscard }) => {
    const navigate = useNavigate();

    const { updateDamageReport } = useDamageReports();

    const getThumbnailImage = (damage) => {
        let thumbnail = damage.images.find((image) => {
            return image.isThumbnail === true;
        })
        return (thumbnail == null ? damage.images[0] : thumbnail);
    }

    if (damages.length === 0) {
        return <Container textAlign='center'>There are no damages.</Container>
    }

    return (
        <>
            <Grid relaxed='very' centered>
                <Grid.Column >
                    {damages.map(damage =>
                        <Container key={damage.id}>
                            <Segment className='damage' >
                                <Label className={damage.type} attached='top right' size='large'>{damage.type}</Label>
                                <Header >
                                    &nbsp;&nbsp;&nbsp;&nbsp; <Moment format="LLLL Z" local>{damage.createdAt}</Moment>
                                </Header>
                                <Grid stackable padded centered>
                                    <Grid.Column width={10} stretched>

                                        <Segment size='large'>
                                            <p>{damage.description}</p>
                                        </Segment>

                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Card
                                            onClick={() => navigate(`../vehicles/${damage.vehicleId}`)}
                                            header={<Header>
                                                <Icon name='car' />
                                                {damage.vehicleRegistrationPlate}
                                            </Header>}
                                            meta={`${damage.vehicleMake} ${damage.vehicleModel} `}
                                            description={damage.bookingSiteName}
                                            icon='car'
                                        />
                                    </Grid.Column>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <Popup
                                                inverted
                                                on='hover'
                                                content='Go to full damage report'
                                                position='top center'
                                                trigger={
                                                    <Card fluid onClick={() => navigate(`/damages/${damage.id}`)}>
                                                        <Image centered fluid rounded src={getThumbnailImage(damage).pathToImage} />
                                                    </Card>
                                                } />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {quickDiscard && (
                                        <Button basic color='red' content='Discard' icon='close'
                                            disabled={damage.type !== 'found'}
                                            onClick={() => updateDamageReport(damage.id, 'discarded')} />
                                    )}
                                </Grid>
                            </Segment>
                            &nbsp;
                        </Container>
                    )}
                </Grid.Column>
            </Grid>
        </>
    )
}

export default DamageReportsList;