import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Icon, Label, Message, Modal } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../api";
import { useForm, FormProvider } from "react-hook-form";
import { FormInput, FormSelect, DateTimePicker } from "../form";
import { date, object, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isLoading } from "../../utils/isLoading";

const BOOKING_STATUSES = [
  "approved",
  "ongoing",
  "ended",
  "cancelled",
  "expired",
];

const BOOKING_STATUS_OPTIONS = BOOKING_STATUSES.map((status) => ({
  key: status,
  text: <Label className={status}>{status}</Label>,
  value: status,
}));

const requiredString = string().required();

const validationSchema = object({
  userId: requiredString.label("User"),
  startTime: date().required().label("Start time"),
  endTime: date()
    .required()
    .min(ref("startTime"), "End time must be after start time")
    .label("End time"),
  status: requiredString.label("Status"),
  vehicleId: requiredString.label("Product"),
  siteId: requiredString.label("Site"),
  vehicleEtagNumber: string().label("Etag number"),
}).required();

/**
 * A modal with a form that creates a booking.
 * @param {{
 *  trigger: ReactNode
 *  defaultSiteId: string
 * }} props
 */
export function CreateBookingModal({ trigger, defaultSiteId }) {
  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState({ state: "loading" });
  const [products, setProducts] = useState({ state: "loading" });
  const [sites, setSites] = useState({ state: "loading" });

  const formMethods = useForm({
    defaultValues: {
      userId: null,
      startTime: null,
      endTime: null,
      status: "approved",
      vehicleId: null,
      siteId: defaultSiteId ?? null,
      vehicleEtagNumber: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setError,
    setValue,
    trigger: triggerValidation,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = formMethods;

  const onSubmit = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();

      const requestObject = {
        userId: data.userId,
        startTime: data.startTime,
        endTime: data.endTime,
        status: data.status,
        vehicleId: data.vehicleId,
        siteId: data.siteId,
        vehicleEtagNumber: data.vehicleEtagNumber,
      };

      try {
        await api.createBooking(accessToken, requestObject);
      } catch {
        setError("root.serverError", {
          type: "500",
          message: "API error. Contact Ike.",
        });
      }
    },
    [getAccessTokenSilently, setError]
  );

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    const fetchedUsers = await api.getUsers(accessToken);
    const fetchedProducts = await api.getVehicles(accessToken);
    const fetchedSites = await api.getSites(accessToken);

    setUsers({ state: "success", value: fetchedUsers });
    setProducts({ state: "success", value: fetchedProducts });
    setSites({ state: "success", value: fetchedSites });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(onSubmit)}>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={trigger}
      >
        <Modal.Header>Create a new booking</Modal.Header>

        <Modal.Content>
          <Form
            success={isSubmitSuccessful}
            loading={isSubmitting}
            error={errors.root !== undefined}
          >
            <Form.Group widths="equal">
              <FormSelect
                search
                scrolling
                name="userId"
                label="User"
                options={users.value?.map((user) => ({
                  key: user.id,
                  text: `${user.firstName} ${user.lastName}`,
                  value: user.id,
                }))}
                loading={isLoading(users)}
              />

              <FormSelect
                search
                scrolling
                name="siteId"
                label="Site"
                options={sites.value?.map((site) => ({
                  key: site.id,
                  text: site.name,
                  value: site.id,
                  description: site.address1,
                }))}
                loading={isLoading(sites)}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <DateTimePicker
                name="startTime"
                label="StartTime"
                clearIcon={null}
                // NOTE (Matt): Circular references are not allowed in the validation schema,
                // so trigger is required to ensure the error message disappears when this input is changed
                onChange={() => triggerValidation("endTime")}
              />

              <DateTimePicker
                name="endTime"
                label="End time"
                clearIcon={null}
              />

              <FormSelect
                name="status"
                label="Status"
                options={BOOKING_STATUS_OPTIONS}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <FormSelect
                search
                scrolling
                name="vehicleId"
                label="Product"
                options={products.value?.map((product) => ({
                  key: product.id,
                  text: `${product.make} - ${product.registrationPlate}`,
                  value: product.id,
                  description: product.type,
                }))}
                onChange={(_, { value }) =>
                  setValue(
                    "vehicleEtagNumber",
                    products.value?.find((product) => product.id === value)
                      .etagNumber
                  )
                }
                loading={isLoading(products)}
              />

              <FormInput
                name="vehicleEtagNumber"
                label="Product etag number"
                required={false}
                disabled
              />
            </Form.Group>

            <Message success icon>
              <Icon name="check circle" />
              <Message.Content>
                <Message.Header>Success</Message.Header>
                Successfully created booking
              </Message.Content>
            </Message>

            <Message error icon>
              <Icon name="times circle" />
              <Message.Content>
                <Message.Header>Something went wrong</Message.Header>
                {errors.root?.serverError?.message}
              </Message.Content>
            </Message>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            content="Create booking"
            labelPosition="right"
            icon="add"
            positive
          />
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
}
