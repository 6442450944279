import React, { useCallback, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Message,
  Modal,
} from "semantic-ui-react";
import ProductInputs from "./ProductInputs";
import { FormProvider, useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { FormInput } from "../../form";
import {
  defaultProductValues,
  productValidationSchema,
} from "./schemas/ProductSchema";

const BIKE_MODELS = {
  Lekker: ["Amsterdam Urban", "Jordaan Urban", "Amsterdam", "X"],
  Tern: ["HSD P9"],
};

const requiredString = string().required();
const positiveRequiredNumber = number().positive().required();

const validationSchema = object({
  ...productValidationSchema(BIKE_MODELS),
  weekendRate: positiveRequiredNumber
    .label("Weekend rate")
    .typeError("Weekend rate must be a number"),
  overnightRate: positiveRequiredNumber
    .label("Overnight token")
    .typeError("Overnight rate must be a number"),
  dailyCap: positiveRequiredNumber
    .label("Daily cap")
    .typeError("Daily cap must be a number"),
  deviceId: requiredString.label("Device ID"),
  deviceToken: requiredString.label("Device token"),
}).required();

export default function CreateBikeModal({ trigger }) {
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      ...defaultProductValues,
      hireRatePerHour: "",
      weekendRate: "",
      overnightRate: "",
      deviceId: "",
      deviceToken: "",
      dailyCap: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = formMethods;

  const onSubmit = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();

      try {
        await api.createBike(accessToken, data);
      } catch {
        setError("root.serverError", {
          type: "500",
        });
      }
    },
    [getAccessTokenSilently, setError]
  );

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(onSubmit)}>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={trigger}
      >
        <Modal.Header>Add a new bike</Modal.Header>

        <Modal.Content>
          <Form
            success={isSubmitSuccessful}
            loading={isSubmitting}
            error={errors.root}
          >
            <ProductInputs productModels={BIKE_MODELS} />

            <Header>Rates</Header>

            <Form.Group widths="equal">
              <FormInput
                name="hireRatePerHour"
                label="Hire rate per hour"
                placeholder="13.50"
                type="number"
                icon="dollar"
                iconPosition="left"
                step="any"
              />

              <FormInput
                name="weekendRate"
                label="Weekend rate"
                placeholder="13.50"
                type="number"
                icon="dollar"
                iconPosition="left"
                step="any"
              />

              <FormInput
                name="overnightRate"
                label="Overnight rate"
                placeholder="13.50"
                type="number"
                icon="dollar"
                iconPosition="left"
                step="any"
              />

              <FormInput
                name="dailyCap"
                label="Daily cap"
                placeholder={50}
                type="number"
                icon="dollar"
                iconPosition="left"
              />
            </Form.Group>

            <Header>Dock</Header>

            <Form.Group widths="equal">
              <FormInput name="deviceId" label="Device ID" />
              <FormInput name="deviceToken" label="Device token" />
            </Form.Group>

            <Divider hidden />

            <Message success icon>
              <Icon name="check circle" />
              <Message.Content>
                <Message.Header>Success</Message.Header>
                Successfully added bike
              </Message.Content>
            </Message>

            <Message error icon>
              <Icon name="times circle" />
              <Message.Content>
                <Message.Header>Internal server error</Message.Header>
                Failed to add charger
              </Message.Content>
            </Message>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            content="Add bike"
            labelPosition="right"
            icon="add"
            positive
          />
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
}
