import { useState, useEffect } from 'react';
import { Grid, Form, Button, Message } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { api } from '../api';

const UserAssign = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [email, setEmail ] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [allUsers, setAllUsers] = useState([])
    const [siteUsers, setSiteUsers] = useState([])
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const fetchedUsers = await api.getUsers(accessToken);
            setAllUsers(fetchedUsers);

            const fetchedsiteUsers = await api.getUsersAtSite(id, accessToken);
            setSiteUsers(fetchedsiteUsers);
        }
        fetchData()
    }, [getAccessTokenSilently, id])

    const userWithEmailExists = email => allUsers.some(user => user.email === email);

    const userAlreadyAssigned = email => siteUsers.some(user => user.email === email);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!userWithEmailExists(email)) {
            setErrorMessage('User does not exist');
        } else if (userAlreadyAssigned(email)) {
            setErrorMessage('User already assigned to this site');
        }
        else {
            const user = allUsers.find(user => user.email === email);
            const accessToken = await getAccessTokenSilently();

            const assignedSuccesfully = await api.assignUserToSite(id, user.id, accessToken);

            if (assignedSuccesfully) {
                navigate(`/sites/${id}/users`);
            }
        }
    }

    const handleInputChange = (event) => {
        setEmail(event.target.value);
        setErrorMessage(null);
    }

    const renderError = () => {
        if (errorMessage) {
            return (
                <Message 
                    error
                    header="Cannot assign this user"
                    content={errorMessage}
                />
            );
        }
    };

    return (
        <Grid centered>
            <Grid.Column width={5}>
                <Form error>
                    <Form.Input
                        label='Email'
                        placeholder="Email"
                        value={email}
                        onChange={handleInputChange}
                    />
                    {renderError()}
                    <Button
                        color='blue'
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Form>
            </Grid.Column>
        </Grid> 
    );
};

export default UserAssign;