import { useState } from "react";
import { Table, Checkbox, Button, Header } from "semantic-ui-react";
import ImageModal from "./modals/ImageModal";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";

const DriversLicence = ({ driversLicence, verified, setRender }) => {
  const [newVerified, setNewVerified] = useState(verified);
  const { getAccessTokenSilently } = useAuth0();

  const handeVerifiedChange = () => {
    newVerified ? setNewVerified(false) : setNewVerified(true);
  };

  const HandelSubmit = async (e) => {
    e.preventDefault();

    const accessToken = await getAccessTokenSilently();

    const requestObject = {
      verified: newVerified,
    };
    const success = await api.patchDriversLicense(
      driversLicence.id,
      requestObject,
      accessToken
    );
    if (success) setRender(Math.random());
  };

  if (driversLicence === null) {
    return (
      <Header as="h4" textAlign="center" color="red">
        User has not created driver's licence.
      </Header>
    );
  }

  return (
    <>
      <Table color="blue">
        <Table.Body>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>
              {driversLicence.firstName} {driversLicence.lastName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Country of Issue</Table.Cell>
            <Table.Cell>{driversLicence.countryOfIssue}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Address 1</Table.Cell>
            <Table.Cell>{driversLicence.address1}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Zip</Table.Cell>
            <Table.Cell>{driversLicence.zip}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Expiry Date</Table.Cell>
            <Table.Cell>{driversLicence.expiry}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Selfie Photo</Table.Cell>
            <Table.Cell>
              <ImageModal
                trigger={<Button>View image</Button>}
                imageUrl={driversLicence.pathToSelfiePhoto}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Front Photo</Table.Cell>
            <Table.Cell>
              <ImageModal
                trigger={<Button>View image</Button>}
                imageUrl={driversLicence.pathToFrontPhoto}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Back Photo</Table.Cell>
            <Table.Cell>
              <ImageModal
                trigger={<Button>View image</Button>}
                imageUrl={driversLicence.pathToBackPhoto}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Checkbox
        toggle
        label={newVerified ? "verifed" : "not verified"}
        checked={newVerified}
        onChange={handeVerifiedChange}
      />
      <Button
        disabled={verified === newVerified}
        color="blue"
        floated="right"
        onClick={HandelSubmit}
      >
        Save
      </Button>
    </>
  );
};

export default DriversLicence;
