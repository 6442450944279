import { Pagination as SUIPagination } from "semantic-ui-react";

export const PAGINATION_SETTINGS = {
  RESULTS_PER_PAGE: 15,
  BOUNDARY_RANGE: 1,
  SIBLING_RANGE: 1,
};

/**
 * Returns the items that are on the current page.
 * @param {Array} filteredItems
 * @param {number} activePage
 * @returns {Array}
 */
export const getPaginatedItems = (filteredItems, activePage) => {
  const sliceStart = (activePage - 1) * PAGINATION_SETTINGS.RESULTS_PER_PAGE;
  const sliceEnd = sliceStart + PAGINATION_SETTINGS.RESULTS_PER_PAGE;

  const itemSlice = filteredItems.slice(sliceStart, sliceEnd);
  return itemSlice;
};

/**
 * Displays a controlled pagination component.
 * @param {{
 *  activePage: number,
 *  onPageChange: () => {},
 *  itemQuantity: number
 * }} props
 */
export const Pagination = ({ activePage, onPageChange, itemQuantity }) => (
  <SUIPagination
    activePage={activePage}
    boundaryRange={PAGINATION_SETTINGS.BOUNDARY_RANGE}
    onPageChange={(_, { activePage }) => onPageChange(activePage)}
    siblingRange={PAGINATION_SETTINGS.SIBLING_RANGE}
    totalPages={Math.ceil(itemQuantity / PAGINATION_SETTINGS.RESULTS_PER_PAGE)}
    ellipsisItem={undefined}
    firstItem={undefined}
    lastItem={undefined}
    prevItem={undefined}
    nextItem={undefined}
  />
);
