import React, { useCallback, useState } from "react";
import {
  Modal,
  Icon,
  Button,
  Table,
  Label,
  Message,
  Grid,
  Segment,
  Divider,
  Header,
} from "semantic-ui-react";
import { now } from "moment";
import { date, number, object } from "yup";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";
import { milesToKm, kmToMiles } from "../../utils/distance";
import { dateTimeFormat } from "../../utils/date";
import { DateTimePicker, Input } from "../form";

const validationSchema = object({
  actualEndTime: date()
    .required()
    .label("Actual end time")
    .typeError("Actual end time must be a date"),
  mileageAfter: number()
    .required()
    .label("End km")
    .typeError("End km must be a number"),
}).required();

export const EndBookingModal = ({
  isOpen,
  onClose,
  booking,
  onGetMileageAfter,
  onEndBooking,
  activeStep,
  setActiveStep,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [prices, setPrices] = useState();
  const [mileageAfter, setMileageAfter] = useState({
    loading: false,
    value: "",
  });
  const formMethods = useForm({
    defaultValues: {
      actualEndTime: null,
      mileageAfter: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    setError,
  } = formMethods;

  const onSubmit = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();
      const mileageAfterInMiles = kmToMiles(getValues("mileageAfter"));

      setMileageAfter((prev) => ({
        ...prev,
        value: mileageAfterInMiles,
      }));

      try {
        const fetchedPrices = await api.getBookingPrice(
          booking.id,
          {
            actualEndTime: data.actualEndTime,
            mileageAfter: mileageAfterInMiles,
          },
          accessToken
        );

        setPrices(fetchedPrices);
        setActiveStep(1);
      } catch {
        setError("root.serverError", {
          type: "500",
        });
      }
    },
    [booking.id, getAccessTokenSilently, getValues, setActiveStep, setError]
  );

  const handleEndBooking = useCallback(() => {
    try {
      onEndBooking({
        actualEndTime: getValues("actualEndTime"),
        mileageAfter: mileageAfter.value,
      });
    } catch (e) {
      setError("root.serverError", {
        type: "500",
        message: "Failed to end booking",
      });
    }
    onClose();
  }, [getValues, mileageAfter.value, onClose, onEndBooking, setError]);

  const handleGetMileageAfter = useCallback(async () => {
    setMileageAfter((prev) => ({ ...prev, loading: true }));

    try {
      const res = await onGetMileageAfter();
      setValue("mileageAfter", res);
    } catch {
      setError("root.serverError", {
        type: "500",
        message: "Failed to get end km",
      });
    }

    setMileageAfter((prev) => ({ ...prev, loading: false }));
  }, [onGetMileageAfter, setError, setValue]);

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(onSubmit)}>
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Header>End Booking</Modal.Header>

        <Modal.Content>
          {activeStep === 0 && (
            <FormStep
              booking={booking}
              isLoadingMileageAfter={mileageAfter.loading}
              handleGetMileageAfter={handleGetMileageAfter}
              onSubmit={onSubmit}
            />
          )}

          {activeStep === 1 && prices && (
            <ConfirmationStep
              prices={prices}
              onEndBooking={handleEndBooking}
              setActiveStep={setActiveStep}
            />
          )}

          {errors.root && (
            <Message error icon>
              <Icon name="times circle" />
              <Message.Content>
                <Message.Header>Internal server error</Message.Header>
                {errors.root.serverError.message}
              </Message.Content>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={onClose}>
            <Icon name="close" />
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
};

const FormStep = ({
  booking,
  isLoadingMileageAfter,
  handleGetMileageAfter,
  onSubmit,
}) => {
  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <>
      <Table definition collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Old details</Table.HeaderCell>
            <Table.HeaderCell>New details</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell> Start Time: </Table.Cell>

            <Table.Cell>
              {dateTimeFormat.format(new Date(booking.startTime))}
            </Table.Cell>

            <Table.Cell />
            <Table.Cell />
          </Table.Row>

          <Table.Row>
            <Table.Cell>End Time:</Table.Cell>

            <Table.Cell>
              {dateTimeFormat.format(new Date(booking.endTime))}
            </Table.Cell>

            <Table.Cell />
            <Table.Cell />
          </Table.Row>

          <Table.Row>
            <Table.Cell>Actual Start Time:</Table.Cell>

            <Table.Cell>
              {booking.actualStartTime == null
                ? "N/A"
                : dateTimeFormat.format(new Date(booking.actualStartTime))}
            </Table.Cell>

            <Table.Cell />
            <Table.Cell />
          </Table.Row>

          <Table.Row>
            <Table.Cell>Actual End Time:</Table.Cell>

            <Table.Cell>
              {booking.actualEndTime == null
                ? "N/A"
                : dateTimeFormat.format(new Date(booking.actualEndTime))}
            </Table.Cell>

            <Table.Cell>
              <DateTimePicker name="actualEndTime" />
            </Table.Cell>

            <Table.Cell>
              <Button
                icon
                compact
                labelPosition="left"
                color="teal"
                size="small"
                onClick={() => setValue("actualEndTime", new Date(now()))}
              >
                <Icon name="clock" />
                Now
              </Button>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell>
              <Label className={booking.status}>{booking.status}</Label>
            </Table.Cell>

            <Table.Cell>
              <Label className="ended">ended</Label>
            </Table.Cell>

            <Table.Cell />
          </Table.Row>

          <Table.Row>
            <Table.Cell>Rate</Table.Cell>
            <Table.Cell>{booking.rate}</Table.Cell>

            <Table.Cell />
            <Table.Cell />
          </Table.Row>

          <Table.Row>
            <Table.Cell>Start Km:</Table.Cell>

            <Table.Cell>
              {booking.mileageBefore === 0
                ? "N/A"
                : milesToKm(booking.mileageBefore).toFixed(2)}
            </Table.Cell>

            <Table.Cell />
            <Table.Cell />
          </Table.Row>

          <Table.Row>
            <Table.Cell>End Km:</Table.Cell>
            <Table.Cell>
              {booking.mileageAfter === 0
                ? "N/A"
                : milesToKm(booking.mileageAfter).toFixed(2)}
            </Table.Cell>

            <Table.Cell>
              <Input
                name="mileageAfter"
                label="km"
                labelPosition="right"
                placeholder="543.00"
                type="number"
                fluid
              />
            </Table.Cell>

            <Table.Cell>
              <Button
                icon
                compact
                labelPosition="left"
                color="teal"
                size="small"
                loading={isLoadingMileageAfter}
                disabled={isLoadingMileageAfter}
                onClick={handleGetMileageAfter}
              >
                <Icon name="tachometer alternate" />
                Fetch
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Button
        color="blue"
        onClick={() => handleSubmit(onSubmit)}
        loading={isSubmitting}
      >
        Next
        <Icon name="chevron right" />
      </Button>
    </>
  );
};

const ConfirmationStep = ({ prices, onEndBooking, setActiveStep }) => {
  return (
    <>
      <Header size="small">Confirm price</Header>

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              <Header>
                <Header.Subheader>Pre-authorised amount</Header.Subheader>$
                {prices.preAuthorizedAmount.toFixed(2)}
              </Header>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment>
              <Header>
                <Header.Subheader>Final price</Header.Subheader>$
                {prices.finalPrice.toFixed(2)}
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden section />

      <Message
        warning
        icon="warning sign"
        header="Are you sure you want to end the booking?"
      />

      <Grid columns={2}>
        <Grid.Column>
          <Button basic onClick={() => setActiveStep(0)}>
            <Icon name="chevron left" />
            Back
          </Button>
        </Grid.Column>

        <Grid.Column>
          <Button color="red" floated="right" onClick={onEndBooking}>
            <Icon name="delete calendar" />
            End booking
          </Button>
        </Grid.Column>
      </Grid>
    </>
  );
};
