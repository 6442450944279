import { Header, Grid, Button, Icon, Popup } from 'semantic-ui-react';
import { useState } from 'react';
import { useUserAccess } from '../components/UserAccessManager';
import { Loader } from '../components/Loader';
import DamageReportsList from '../components/damageReportList';
import { useDamageReports } from '../components/DamageReportsContext';

const Damages = () => {
    const [editing, setEditing] = useState(false);

    const { damageReports, isLoading } = useDamageReports();
    const { isCommunityManager } = useUserAccess();

    const handleToggleEditMode = () => {
        setEditing(!editing);
    }

    const filterDamages = (damages) => {
        return (damages.filter(damage => damage.type === 'found'));
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid centered>
                <Grid.Column computer={12} mobile={15} >
                    <Header textAlign='center' as='h1' >New Damage Reports
                        {!isCommunityManager && (<Popup
                            on='hover'
                            content='Toggle quick discard mode'
                            position='left center'
                            trigger={<Button basic floated='right' onClick={() => handleToggleEditMode()}>
                                {editing ? <Icon name='close' /> : <Icon name='edit' />}
                            </Button>}
                        />
                        )}
                    </Header>
                    &nbsp;
                    <DamageReportsList damages={filterDamages(damageReports)} quickDiscard={editing} />
                </Grid.Column>
            </Grid>
        </Loader>
    )
}

export default Damages;