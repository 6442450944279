import React, { useCallback, useState } from "react";
import { Button } from "semantic-ui-react";

/**
 * Displays a group of buttons that call a function when clicked
 * @param {{
 *  content: string[]
 *  onClick: (text: string) => void
 *  defaultIndex: number
 * }} props
 */
export function ButtonGroup({ content, onClick, defaultIndex, ...props }) {
  const [activeIndex, setActiveIndex] = useState(defaultIndex ?? 0);

  const handleOnClick = useCallback(
    (index, text) => {
      setActiveIndex(index);
      onClick(text);
    },
    [onClick]
  );

  return (
    <Button.Group {...props} basic>
      {content.map((text, index) => (
        <Button
          key={index}
          active={index === activeIndex}
          tabIndex={index}
          onClick={() => handleOnClick(index, text)}
        >
          {text}
        </Button>
      ))}
    </Button.Group>
  );
}
