import React from "react";
import { Label, Input as SUIInput } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the Semantic UI input component to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 * }} props
 */
export function Input({ name, required, ...props }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ?? true,
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <>
          <SUIInput
            {...props}
            {...field}
            // NOTE (Matt): Stop propagation to prevent keys typed in the input from
            // triggering events in any parent components
            onKeyDown={(e) => e.stopPropagation()}
            error={error && true}
          />

          {error && (
            <Label
              basic
              color="red"
              pointing="above"
              style={{ display: "block", width: "fit-content" }}
            >
              {error.message}
            </Label>
          )}
        </>
      )}
    />
  );
}
