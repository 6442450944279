import React from "react";
import { Grid, Image, Modal } from "semantic-ui-react";

const ImageModal = ({ trigger, imageUrl }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      basic
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
      <Modal.Content>
        <Grid>
          <Grid.Column textAlign="center">
            <Image src={imageUrl} />
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default ImageModal;
