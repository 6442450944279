import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import './App.css';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import Login from './pages/login';
import Navbar from './components/Navbar';
import Sites from './pages/sites';
import User from './pages/user';
import UserAssign from './pages/userAssign';
import UsersAtSite from './pages/usersAtSite';
import Users from './pages/users';
import Booking from './pages/booking';
import Home from './pages/home';
import Vehicles from './pages/vehicles';
import ProtectedRoute from './auth/protected-route';
import VehicleBookings from './pages/vehicleBookings';
import VehicleOverview from './pages/vehicleOverview';
import Damages from './pages/damages';
import DamageReport from './pages/damageReport'
import OAuth2 from './pages/OAuth2';
import { OAuthPopup } from '@tasoskakour/react-use-oauth2';
import { DamageReportProvider } from './components/DamageReportsContext';
import { UserAccessManager, COMMUNITY_MANAGER_ROLE } from './components/UserAccessManager';
import { ChatWindowManager } from './components/chat-window-manager/ChatWindowManager';
import Bookings from './pages/bookings';

function App() {
  const excludedRoutes = ['/oauth2', '/oauth2/callback'];

  return (
    <BrowserRouter>
        <Auth0ProviderWithHistory>
          <UserAccessManager>
            <DamageReportProvider>
              <ChatWindowManager>
                {!excludedRoutes.includes(window.location.pathname) && <Navbar />}
                <Container className="main">
                  <Routes>
                    <Route path='/login/*' element={<Login/>} />
                    <Route path='/oauth2/callback' element={<OAuthPopup/>} />
                    <Route path='/oauth2' element={<OAuth2/>} />

                    <Route element={<ProtectedRoute component={Home} /> } />
                    <Route path='/' element={<ProtectedRoute component={Home} /> } />
                    <Route path='/users/*' element={<ProtectedRoute restrictedRoles={[COMMUNITY_MANAGER_ROLE]} component={Users} /> } />
                    <Route path='/users/:id' element={<ProtectedRoute component={User} /> } />
                    <Route path='/bookings' element={<ProtectedRoute component={Bookings} /> } />
                    <Route path='/bookings/:id' element={<ProtectedRoute component={Booking} /> } />
                    <Route path='/sites/*' element= {<ProtectedRoute component={Sites} /> } />
                    <Route path='/sites/:id/vehiclebookings' element={<ProtectedRoute component={VehicleBookings} /> } />
                    <Route path='/sites/:id/users' element={<ProtectedRoute restrictedRoles={[COMMUNITY_MANAGER_ROLE]} component={UsersAtSite} /> } />
                    <Route path='/sites/:id/assign' element={<ProtectedRoute component={UserAssign} /> } />
                    <Route path='/vehicles/*' element={<ProtectedRoute component={Vehicles} /> } />
                    <Route path='/vehicles/:id/' element={<ProtectedRoute component={VehicleOverview} /> } />
                    <Route path='/damages/*' element={<ProtectedRoute component={Damages} /> } />
                    <Route path='/damages/:id' element={<ProtectedRoute component={DamageReport} /> } />
                  </Routes>
                </Container>
              </ChatWindowManager>
            </DamageReportProvider>
          </UserAccessManager>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
  );
}

export default App;
