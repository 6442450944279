import { Header, Table, Grid, Button, Icon } from "semantic-ui-react";
import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import packageInfo from "../../package.json";
import { isLoading } from "../utils/isLoading";
import { Loader } from "../components/Loader";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { useUserAccess } from "../components/UserAccessManager";
import { openInNewTab } from "../utils/openInNewTab";
import { BookingStatusLabel } from "../components/BookingStatusLabel";
import { CreateBookingModal } from "../components/modals/CreateBookingModal";

const Home = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isCommunityManager } = useUserAccess();
  const navigate = useNavigate();

  const [bookings, setBookings] = useState({ state: "loading", value: [] });
  const version = packageInfo.version;

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedBookings = await api.getAllBookings("ongoing", accessToken);

    setBookings({ state: "success", value: fetchedBookings });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
    let timer;

    timer = setInterval(() => {
      const sec = new Date().getSeconds();
      if (sec) return;

      fetchData();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [fetchData]);

  return (
    <>
      <div style={{ position: "absolute", right: 50, top: 50 }}>
        Admin app version: {version}
      </div>

      <Header as="h1" textAlign="center">
        Dashboard
      </Header>

      <Loader isLoading={isLoading(bookings)}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <CreateBookingModal
                trigger={
                  <Button color="blue" floated="right">
                    <Icon name="add" />
                    New booking
                  </Button>
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Table selectable textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="9">
                      Ongoing Bookings List
                    </Table.HeaderCell>
                  </Table.Row>

                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    {!isCommunityManager && (
                      <Table.HeaderCell>Phone</Table.HeaderCell>
                    )}
                    <Table.HeaderCell>Vehicle</Table.HeaderCell>
                    <Table.HeaderCell>Site</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Due Back</Table.HeaderCell>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {bookings.value.map((booking) => (
                    <Table.Row
                      key={booking.id}
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      onMouseUp={(e) =>
                        openInNewTab(e, `bookings/${booking.id}`)
                      }
                    >
                      {isCommunityManager ? (
                        <Table.Cell>
                          {booking.customerFirstName}{" "}
                          {booking.customerLastName[0]}
                        </Table.Cell>
                      ) : (
                        <>
                          <Table.Cell>
                            {booking.customerFirstName}{" "}
                            {booking.customerLastName}
                          </Table.Cell>

                          <Table.Cell>{booking.customerPhoneNumber}</Table.Cell>
                        </>
                      )}
                      <Table.Cell>
                        {booking.vehicleMake} -{" "}
                        {booking.vehicleRegistrationPlate}
                      </Table.Cell>

                      <Table.Cell>{booking.siteName}</Table.Cell>
                      <Table.Cell>
                        <BookingStatusLabel
                          booking={booking}
                          displayTimeOverdue
                        />
                      </Table.Cell>

                      <Table.Cell>
                        <Moment format="llll Z" local>
                          {booking.bookingEndTime}
                        </Moment>
                      </Table.Cell>

                      <Table.Cell>
                        <Moment
                          format="H[h]:mm[m]"
                          duration={booking.bookingStartTime}
                          date={booking.bookingEndTime}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Loader>
    </>
  );
};

export default Home;
