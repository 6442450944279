import { useState, useEffect, useCallback } from "react";
import { Table, Icon, Grid } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import { Loader } from "../components/Loader";
import { Pagination, getPaginatedItems } from "../components/Pagination";
import { TextInput } from "../components/TextInput";
import { Dropdown } from "../components/Dropdown";
import { isLoading } from "../utils/isLoading";
import { shortenPhoneNumber } from "../utils/numbers";
import { openInNewTab } from "../utils/openInNewTab";

const Users = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState({ state: "loading", value: [] });
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({
    searchValue: "",
    licenceVerified: "all",
    siteAssigned: "all",
    requestedCarService: "all",
    requestedChargerService: "all",
    requestedBikeService: "all",
  });

  const { getAccessTokenSilently } = useAuth0();

  const applyFilters = useCallback((allUsers, filters) => {
    return allUsers.value.filter((user) => {
      // Search in name, email, or phone number
      const searchMatch =
        filters.searchValue === "" ||
        (user.firstName + " " + user.lastName)
          .toLowerCase()
          .includes(filters.searchValue.toLowerCase()) ||
        user.email.startsWith(filters.searchValue.toLowerCase()) ||
        user.phoneNumber.includes(
          shortenPhoneNumber(filters.searchValue.toLowerCase())
        );

      // Show all users, or only users where driversLicenceVerified is verified or unverified
      const licenseMatch =
        filters.licenceVerified === "all" ||
        user.driversLicenceVerified ===
          (filters.licenceVerified === "verified");

      // Show all users, or only users where siteAssigned is assigned or not assigned
      const siteMatch =
        filters.siteAssigned === "all" ||
        user.siteAssigned === (filters.siteAssigned === "assigned");

      // Show all users, or only users where requestedServicesList contains car or doesn't contain car
      const carMatch =
        filters.requestedCarService === "all" ||
        user.requestedServicesList.includes("car") ===
          (filters.requestedCarService === "yes");

      // Show all users, or only users where requestedServicesList contains charger or doesn't contain charger
      const chargerMatch =
        filters.requestedChargerService === "all" ||
        user.requestedServicesList.includes("charger") ===
          (filters.requestedChargerService === "yes");

      // Show all users, or only users where requestedServicesList contains bike or doesn't contain bike
      const bikeMatch =
        filters.requestedBikeService === "all" ||
        user.requestedServicesList.includes("bike") ===
          (filters.requestedBikeService === "yes");

      return (
        searchMatch &&
        licenseMatch &&
        siteMatch &&
        carMatch &&
        chargerMatch &&
        bikeMatch
      );
    });
  }, []);

  const filterUsers = useCallback(
    (allUsers, filters) => {
      const filteredUserResults = applyFilters(allUsers, filters);
      setFilteredUsers(filteredUserResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  const updateFilters = (updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  };

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    const fetchedUsers = await api.getUsers(accessToken);

    setAllUsers({ state: "success", value: fetchedUsers });
    setFilteredUsers(fetchedUsers);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    filterUsers(allUsers, filters);
  }, [allUsers, filterUsers, filters]);

  return (
    <>
      <Grid stackable columns={3}>
        <Grid.Column>
          <TextInput
            fluid
            placeholder="Name, email or phone"
            onSubmit={(searchValue) => updateFilters({ searchValue })}
          />
        </Grid.Column>
      </Grid>

      <Loader isLoading={isLoading(allUsers)}>
        <Table textAlign="center" selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2">Name</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Email</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Phone</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2}>
                <Dropdown
                  label="Licence verified"
                  items={[
                    { text: "All", value: "all" },
                    { text: "Verified", value: "verified" },
                    { text: "Not verified", value: "unverified" },
                  ]}
                  onSelect={(item) =>
                    updateFilters({ licenceVerified: item.value })
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2}>
                <Dropdown
                  label="Site assigned"
                  items={[
                    { text: "All", value: "all" },
                    { text: "Assigned", value: "assigned" },
                    { text: "Not assigned", value: "not assigned" },
                  ]}
                  onSelect={(item) =>
                    updateFilters({ siteAssigned: item.value })
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                Requested Services
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell>
                <Dropdown
                  label="Car"
                  items={[
                    { text: "All", value: "all" },
                    { text: "Car", value: "yes" },
                    { text: "Not car", value: "no" },
                  ]}
                  onSelect={(item) =>
                    updateFilters({ requestedCarService: item.value })
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Dropdown
                  label="Charger"
                  items={[
                    { text: "All", value: "all" },
                    { text: "Charger", value: "yes" },
                    { text: "Not charger", value: "no" },
                  ]}
                  onSelect={(item) =>
                    updateFilters({ requestedChargerService: item.value })
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Dropdown
                  label="Bike"
                  items={[
                    { text: "All", value: "all" },
                    { text: "Bike", value: "yes" },
                    { text: "Not bike", value: "no" },
                  ]}
                  onSelect={(item) =>
                    updateFilters({ requestedBikeService: item.value })
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPaginatedItems(filteredUsers, activePage).map((user) => (
              <Table.Row
                key={user.id}
                onClick={() => navigate(`${user.id}`)}
                onMouseUp={(e) => openInNewTab(e, `users/${user.id}`)}
              >
                <Table.Cell>
                  {user.firstName} {user.lastName}
                </Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.phoneNumber}</Table.Cell>
                <Table.Cell>
                  {user.driversLicenceVerified ? <CheckIcon /> : <CrossIcon />}
                </Table.Cell>
                <Table.Cell>
                  {user.siteAssigned ? <CheckIcon /> : <CrossIcon />}
                </Table.Cell>
                <Table.Cell>
                  {user.requestedServicesList.includes("car") && <CircleIcon />}
                </Table.Cell>
                <Table.Cell>
                  {user.requestedServicesList.includes("charger") && (
                    <CircleIcon />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {user.requestedServicesList.includes("bike") && (
                    <CircleIcon />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Grid stackable>
          <Grid.Column width={4}>
            <Pagination
              activePage={activePage}
              onPageChange={(page) => setActivePage(page)}
              itemQuantity={filteredUsers.length}
            />
          </Grid.Column>
        </Grid>
      </Loader>
    </>
  );
};

export default Users;

const CheckIcon = () => <Icon name="check" color="green" />;
const CrossIcon = () => <Icon name="close" color="red" />;
const CircleIcon = () => <Icon name="circle outline" color="teal" />;
