const dev = {
    auth0: {
        DOMAIN: "ohmiego-dev.au.auth0.com",
        CLIENT_ID: "zxUM18Q7wCHtH26JcztVeY3y44SH4CsT",
        AUDIENCE: "https://ohmie-go-backend",
        NAMESPACE: "https://ohmiego-namespace.com",
    },
    backendApi: {
        URL: "http://localhost:3000/api/v1/admin"
    },
    oauth2: {
        CLIENTID: "ba87935a83db-48a4-ac8f-67a7ed50ff1a",
        REDIRECTURI: "http://localhost:4000/oauth2/callback",
        TOKENURL: "http://localhost:3000/oauth2/callback"
    }
}

const staging = {
    auth0: {
        DOMAIN: "ohmiego-dev.au.auth0.com",
        CLIENT_ID: "zxUM18Q7wCHtH26JcztVeY3y44SH4CsT",
        AUDIENCE: "https://ohmie-go-backend",
        NAMESPACE: "https://ohmiego-namespace.com"
    },
    backendApi: {
        URL: "https://api.stg.ohmie.co/api/v1/admin"
    },
    oauth2: {
        CLIENTID: "ba87935a83db-48a4-ac8f-67a7ed50ff1a",
        REDIRECTURI: "https://admin-staging.ohmiego.com/oauth2/callback",
        TOKENURL: "https://api.stg.ohmie.co/oauth2/callback"
    }
}

const prod = {
    auth0: {
        DOMAIN: "ohmiego.au.auth0.com",
        CLIENT_ID: "gnAqr0qGnz0CopOphH6sPibOFQYAjP4z",
        AUDIENCE: "https://ohmie-go-backend",
        NAMESPACE: "https://ohmiego-namespace.com"
    },
    backendApi: {
        URL: "https://api.ohmie.co/api/v1/admin"
    },
    oauth2: {
        CLIENTID: "c3d63c5f9565-458c-b4c1-f202eb6f4df4",
        REDIRECTURI: "https://admin-production.ohmiego.com/oauth2/callback",
        TOKENURL: "https://api.ohmie.co/oauth2/callback"
    }
}

function get_configuration_for_environment(react_stage){
    let configuration = dev;
    switch (react_stage){
        case 'prod':
            configuration = prod;
            break;
        case 'staging':
            configuration = staging;
            break
        default:
            configuration = dev;
    }
    return configuration;
}

const config = get_configuration_for_environment(process.env.REACT_APP_STAGE)
const exportedObject = {
    // Add common config values here
    ...config
}

export default exportedObject;
