import React, { useCallback, useState } from "react";
import {
  Modal,
  Icon,
  Button,
  Table,
  Label,
  Header,
  Message,
} from "semantic-ui-react";
import { milesToKm, kmToMiles } from "../../utils/distance";
import { dateTimeFormat } from "../../utils/date";
import { date, number, object, ref, string } from "yup";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Dropdown, DateTimePicker } from "../form";

const bookingStatuses = ["approved", "ended", "cancelled", "ongoing"];
const bookingStatusOptions = bookingStatuses.map((status) => ({
  key: status,
  text: <Label className={status}>{status}</Label>,
  value: status,
}));

const bookingRates = ["weekend", "normal"];
const bookingRateOptions = bookingRates.map((rate) => ({
  key: rate,
  text: rate,
  value: rate,
}));

const requiredDate = date().required();
const optionalDate = date().notRequired();
const requiredString = string().required();
const requiredNumber = number()
  .min(0)
  .required()
  .transform((value, _) => {
    return isNaN(value) ? null : value;
  });

const validationSchema = object({
  startTime: requiredDate.label("Start time"),
  endTime: requiredDate
    .min(ref("startTime"), "End time must be after start time")
    .label("End time"),
  actualStartTime: optionalDate.label("Actual start time"),
  actualEndTime: optionalDate
    .min(
      ref("actualStartTime"),
      "Actual end time must be after actual start time"
    )
    .label("Actual start time"),
  status: requiredString
    .oneOf(bookingStatuses)
    .label("Status")
    .transform((value, _) => {
      return value === "" ? null : value;
    }),
  rate: requiredString
    .oneOf(bookingRates)
    .label("Rate")
    .transform((value, _) => {
      return value === "" ? null : value;
    }),
  mileageBefore: requiredNumber
    .label("Start km")
    .typeError("Start km must be a number"),
  mileageAfter: requiredNumber
    .label("End km")
    .typeError("End km must be a number"),
});

const EditBookingModal = ({
  isOpen,
  onClose,
  booking,
  onSave,
  onGetMileageBefore,
  onGetMileageAfter,
}) => {
  const [overlapWarningVisible, setOverlapWarningVisible] = useState(false);
  const [isMileageLoading, setIsMileageLoading] = useState({
    mileageBefore: false,
    mileageAfter: false,
  });

  const formMethods = useForm({
    defaultValues: {
      startTime: new Date(booking.startTime),
      endTime: new Date(booking.endTime),
      actualStartTime: new Date(booking.actualStartTime),
      actualEndTime: new Date(booking.actualEndTime),
      status: booking.status,
      rate: booking.rate,
      mileageBefore: milesToKm(booking.mileageBefore),
      mileageAfter: milesToKm(booking.mileageAfter),
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setValue,
    reset,
    setError,
    trigger,
    formState: { isSubmitting, errors },
  } = formMethods;

  const checkIfBookingsOverlap = useCallback(
    (endTime) => {
      if (
        booking.nextBookingTime !== null &&
        endTime >= new Date(booking.nextBookingTime)
      ) {
        setOverlapWarningVisible(true);
      } else {
        setOverlapWarningVisible(false);
      }
    },
    [booking.nextBookingTime]
  );

  const handleSave = useCallback(
    async (data) => {
      data.mileageBefore = kmToMiles(data.mileageBefore);
      data.mileageAfter = kmToMiles(data.mileageAfter);

      try {
        await onSave(data);
        onClose();
      } catch (error) {
        setError("root.serverError", {
          type: "500",
          message: "Failed to update booking",
        });
      }
    },
    [onClose, onSave, setError]
  );

  const handleGetMileageBefore = useCallback(async () => {
    setIsMileageLoading((prev) => ({ ...prev, mileageBefore: true }));

    try {
      const res = await onGetMileageBefore();
      setValue("mileageBefore", res);
    } catch {
      setError("root.serverError", {
        type: "500",
        message: "Failed to get start km",
      });
    }

    setIsMileageLoading((prev) => ({ ...prev, mileageBefore: false }));
  }, [onGetMileageBefore, setError, setValue]);

  const handleGetMileageAfter = useCallback(async () => {
    setIsMileageLoading((prev) => ({ ...prev, mileageAfter: true }));

    try {
      const res = await onGetMileageAfter();
      setValue("mileageAfter", res);
    } catch {
      setError("root.serverError", {
        type: "500",
        message: "Failed to get end km",
      });
    }

    setIsMileageLoading((prev) => ({ ...prev, mileageAfter: false }));
  }, [onGetMileageAfter, setError, setValue]);

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(handleSave)}>
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Header>Override Booking Details</Modal.Header>

        <Modal.Content>
          <Table definition collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Old details</Table.HeaderCell>
                <Table.HeaderCell>New details</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Start Time:</Table.Cell>
                <Table.Cell>
                  {dateTimeFormat.format(new Date(booking.startTime))}
                </Table.Cell>

                <Table.Cell>
                  <DateTimePicker
                    name="startTime"
                    clearIcon={null}
                    // NOTE (Matt): Circular references are not allowed in the validation schema,
                    // so trigger is required to ensure the error message disappears when this input is changed
                    onChange={() => trigger("endTime")}
                  />
                </Table.Cell>

                <Table.Cell />
              </Table.Row>

              <Table.Row>
                <Table.Cell>End Time:</Table.Cell>
                <Table.Cell>
                  {dateTimeFormat.format(new Date(booking.endTime))}
                </Table.Cell>

                <Table.Cell>
                  <DateTimePicker
                    name="endTime"
                    clearIcon={null}
                    onChange={checkIfBookingsOverlap}
                  />
                  {overlapWarningVisible && (
                    <Label color="orange" basic pointing="above">
                      <Icon name="exclamation circle" />
                      Warning! May conflict with next booking
                    </Label>
                  )}
                </Table.Cell>

                <Table.Cell />
              </Table.Row>

              <Table.Row>
                <Table.Cell>Actual Start Time:</Table.Cell>

                <Table.Cell>
                  {booking.actualStartTime == null
                    ? "N/A"
                    : dateTimeFormat.format(new Date(booking.actualStartTime))}
                </Table.Cell>

                <Table.Cell>
                  <DateTimePicker
                    name="actualStartTime"
                    required={false}
                    // NOTE (Matt): Circular references are not allowed in the validation schema,
                    // so trigger is required to ensure the error message disappears when this input is changed
                    onChange={() => trigger("actualEndTime")}
                  />
                </Table.Cell>

                <Table.Cell>
                  <CurrentDateButton field="actualStartTime" />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Actual End Time:</Table.Cell>

                <Table.Cell>
                  {booking.actualEndTime == null
                    ? "N/A"
                    : dateTimeFormat.format(new Date(booking.actualEndTime))}
                </Table.Cell>

                <Table.Cell>
                  <DateTimePicker name="actualEndTime" required={false} />
                </Table.Cell>

                <Table.Cell>
                  <CurrentDateButton field="actualEndTime" />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Status</Table.Cell>
                <Table.Cell>
                  <Label className={booking.status}>{booking.status}</Label>
                </Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="status"
                    placeholder={booking.status}
                    fluid
                    options={bookingStatusOptions}
                  />
                </Table.Cell>

                <Table.Cell />
              </Table.Row>

              <Table.Row>
                <Table.Cell>Rate</Table.Cell>
                <Table.Cell>{booking.rate}</Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="rate"
                    placeholder={booking.rate}
                    fluid
                    options={bookingRateOptions}
                  />
                </Table.Cell>

                <Table.Cell />
              </Table.Row>

              <Table.Row>
                <Table.Cell>Start Km:</Table.Cell>

                <Table.Cell>
                  {booking.mileageBefore === 0
                    ? "N/A"
                    : milesToKm(booking.mileageBefore).toFixed(2)}
                </Table.Cell>

                <Table.Cell>
                  <Input
                    name="mileageBefore"
                    type="number"
                    step="any"
                    placeholder={milesToKm(booking.mileageBefore).toFixed(2)}
                    labelPosition="right"
                    label="km"
                    fluid
                  />
                </Table.Cell>

                <Table.Cell>
                  <Button
                    icon
                    compact
                    labelPosition="left"
                    color="teal"
                    size="small"
                    loading={isMileageLoading.mileageBefore}
                    disabled={isMileageLoading.mileageBefore}
                    onClick={handleGetMileageBefore}
                  >
                    <Icon name="tachometer alternate" />
                    Fetch
                  </Button>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>End Km:</Table.Cell>
                <Table.Cell>
                  {booking.mileageAfter === 0
                    ? "N/A"
                    : milesToKm(booking.mileageAfter).toFixed(2)}
                </Table.Cell>

                <Table.Cell>
                  <Input
                    name="mileageAfter"
                    type="number"
                    step="any"
                    placeholder={milesToKm(booking.mileageAfter).toFixed(2)}
                    labelPosition="right"
                    label="km"
                    fluid
                  />
                </Table.Cell>

                <Table.Cell>
                  <Button
                    icon
                    compact
                    labelPosition="left"
                    color="teal"
                    size="small"
                    loading={isMileageLoading.mileageAfter}
                    disabled={isMileageLoading.mileageAfter}
                    onClick={handleGetMileageAfter}
                  >
                    <Icon name="tachometer alternate" />
                    Fetch
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as="h4">
            Next Booking Time
            <Header.Subheader>
              {booking.nextBookingTime
                ? dateTimeFormat.format(new Date(booking.nextBookingTime))
                : "N/A"}
            </Header.Subheader>
          </Header>

          {errors.root && (
            <Message error icon>
              <Icon name="times circle" />
              <Message.Content>
                <Message.Header>Internal server error</Message.Header>
                {errors.root.serverError.message}
              </Message.Content>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={onClose}>
            <Icon name="close" />
            Cancel
          </Button>

          <Button basic onClick={reset}>
            <Icon name="repeat" />
            Reset
          </Button>

          <Button
            color="green"
            onClick={handleSubmit(handleSave)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            <Icon name="checkmark" />
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
};

const CurrentDateButton = ({ field }) => {
  const { setValue } = useFormContext();

  return (
    <Button
      icon
      compact
      labelPosition="left"
      color="teal"
      size="small"
      onClick={() => setValue(field, new Date())}
    >
      <Icon name="clock" />
      Now
    </Button>
  );
};

export default EditBookingModal;
