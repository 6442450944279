import config from '../config';

const API_URL = config.backendApi.URL;

const getHeaderObject = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
};

export { API_URL, getHeaderObject }