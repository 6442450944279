export const OverdueBooking = (firstName, vehicleRegistration) =>
  `Hi ${firstName},

We can see that your current booking for ${vehicleRegistration} is still ongoing past its end time.

Please ensure you return the vehicle and end your booking via the app as soon as possible.

- Ohmie GO Support`;

export const LateReturn = (firstName, vehicleRegistration, bookingEndTime) =>
  `Hi ${firstName},

Your booking for ${vehicleRegistration} was due back at ${bookingEndTime} and your late return has impacted another customer's booking.

Please return the vehicle as soon as possible. Late fees will apply and are charged at 4x the regular usage rate.

- Ohmie GO Support`;

export const DelayedBooking = (
  firstName,
  vehicleRegistration,
  bookingStartTime
) =>
  `Hi ${firstName},

Your booking today for ${vehicleRegistration} starting at ${bookingStartTime} may be delayed due to a late return from the previous user.

We apologise for the delay and will let you know as soon as the vehicle is back and ready for your booking.

- Ohmie GO Support`;
