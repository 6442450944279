import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { api } from '../api';

const  DamageReportsContext = createContext();

export function useDamageReports() {
  return useContext(DamageReportsContext);
}

export const DamageReportProvider = ({ children }) => {
  const [damageReports, setDamageReports] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
  
      const data = await api.getAllDamageReports(accessToken);
      setDamageReports(data);
      setBadgeCount(data.filter(damage => damage.type === 'found').length);
      setIsLoading(false);
    };

    fetchData();
  }, [])

  const updateDamageReport = async (damageReportId, type) => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
        type: type,
    }

    await api.patchDamageReport(damageReportId, accessToken, requestObject)
        .then(() => {
          setDamageReports(prevDamageReports => prevDamageReports.filter(damageReport => damageReport.id !== damageReportId));
          setBadgeCount(prevCount => prevCount - 1);
        }).catch((_) => {
        });
}

  return (
    <DamageReportsContext.Provider value={{ damageReports, badgeCount, isLoading, updateDamageReport}}>
      {children}
    </DamageReportsContext.Provider>
  );
};
