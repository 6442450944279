import { Loader as SUILoader } from "semantic-ui-react"

/**
 * Displays a loader while the page is loading,\
 * and displays the children when the page is not loading
 * @param {boolean} isLoading 
 * @returns {*}
 */
export const Loader = ({ children, isLoading }) => {
  return isLoading ? <SUILoader active size='big' /> : children;
}
