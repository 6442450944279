import React, { useState } from 'react';
import { Modal, Icon, Button, Table, Input, Dropdown} from 'semantic-ui-react';

const EditVehicleModal = ({ isOpen, onClose, vehicle, sites, onSave }) => {
    const [editedVehicle, setEditedVehicle] = useState('');

    const sitesList = sites.reduce((res, item) => {
        res.push({ key: item.name, text: item.name, value: item.id });
        return res;
    }, []);

    const handleEditField = (fieldName, value) => {
        setEditedVehicle({ ...editedVehicle, [fieldName]: value });
    }

    const handleSave = () => {
        editedVehicle.id = vehicle.id;
        onSave(editedVehicle);
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={onClose} size='small'>
            <Modal.Header>Override Vehicle Details</Modal.Header>
            <Modal.Content>

                <Table definition columns={4}>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell width={1}/>
                            <Table.HeaderCell width={1}>Old details</Table.HeaderCell>
                            <Table.HeaderCell width={2} >New details</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell> Site</Table.Cell>
                            <Table.Cell> {vehicle.site.name} </Table.Cell>
                            <Table.Cell> 
                            <Dropdown
                            placeholder={vehicle.site.name}
                            selection
                            fluid
                            scrolling
                            clearable options={sitesList}
                            onChange={(e, data) => handleEditField('siteId', data.value)} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> Etag number</Table.Cell>
                            <Table.Cell> {vehicle.etagNumber} </Table.Cell>
                            <Table.Cell>
                                <Input
                                    placeholder={vehicle.etagNumber}
                                    onChange={(e) => handleEditField('etagNumber', e.target.value)} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <header>
                Warning! There are no checks to ensure the validity of these changes, please double check all inputs before saving.
                </header>
            </Modal.Content>
            
            <Modal.Actions>
                <Button color="red" onClick={onClose}>
                    <Icon name="close" /> Cancel
                </Button>
                <Button color="green" onClick={handleSave}>
                    <Icon name="checkmark" /> Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default EditVehicleModal;