import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useUserAccess } from "../components/UserAccessManager";
import { Container, Header, Icon } from "semantic-ui-react";

const ProtectedRoute = ({ restrictedRoles, component, ...args }) => {
    const Cp = withAuthenticationRequired(component);
    const {roles} = useUserAccess();

    if(restrictedRoles && roles.find(role => restrictedRoles.includes(role))) {
        return (
            <Container textAlign='center'>
                <Icon size='huge' color='red' name='exclamation circle'/>
                <Header>You do not have permission to view this page.</Header>
            </Container>
        )
    } else{
        return <Cp {...args} />
    }
}

export default ProtectedRoute;
