import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Label,
  Message,
  Modal,
} from "semantic-ui-react";
import { api } from "../../api";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import { FormInput, FormSelect, FormCheckbox } from "../form";
import { object, string, number, boolean, array } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const SITE_TYPES = ["Residential", "Corporate", "Unallocated"];
const TIME_ZONES = ["Australia/Sydney", "Australia/Brisbane"];

const requiredString = string().required();
const requiredTimestamp = requiredString
  .matches(
    /\d{1,2}:\d{1,2}/,
    (field) => `${field.label} must be in the format of HH:MM`
  )
  .length(5);

const validationSchema = object({
  imagePath: string().url().ensure().notRequired().label("Image path"),
  type: requiredString
    .oneOf(["Residential", "Corporate", "Unallocated"])
    .label("Type"),
  servicesAvailable: array()
    .of(string().oneOf(["Charger", "Car", "Bike"]))
    .ensure()
    .required()
    .label("Services available"),
  maxChargerBookingDuration: number()
    .default(180)
    .notRequired()
    .label("Maximum charger booking duration")
    .typeError("Maximum charger booking duration must be a number"),
  monitorIdling: boolean().default(false).required().label("Monitor idling"),
  name: requiredString.label("Name"),
  displayName: requiredString.label("Display name"),
  address1: requiredString.label("Address 1"),
  address2: string().label("Address 2"),
  zip: requiredString
    .length(4, "Zip code must be exactly 4 digits")
    .matches(/\d{4}/, "Zip code must be a number")
    .label("Zip code"),
  timeZoneId: requiredString
    .oneOf(["Australia/Sydney", "Australia/Brisbane"])
    .label("Time zone"),
  latitude: number()
    .required()
    .label("Latitude")
    .typeError("Latitude must be a number"),
  longitude: number()
    .required()
    .label("Longitude")
    .typeError("Longitude must be a number"),
  businessHoursStart: requiredTimestamp
    .default("09:00")
    .label("Business hours start"),
  businessHoursEnd: requiredTimestamp
    .default("16:00")
    .label("Business hours end"),
  freeDuringBusinessHours: boolean()
    .default(false)
    .required()
    .label("Free during business hours"),
  weekendStart: requiredTimestamp.default("16:00").label("Weekend start"),
  weekendEnd: requiredTimestamp.default("09:00").label("Weekend end"),
}).required();

export default function CreateSiteModal({ trigger }) {
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);
  const [chargerSelected, setChargerSelected] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      imagePath: "",
      type: null,
      servicesAvailable: [],
      maxChargerBookingDuration: 180,
      monitorIdling: false,
      name: "",
      displayName: "",
      address1: "",
      address2: "",
      zip: "",
      timeZoneId: null,
      latitude: "",
      longitude: "",
      businessHoursStart: "09:00",
      businessHoursEnd: "16:00",
      freeDuringBusinessHours: false,
      weekendStart: "16:00",
      weekendEnd: "09:00",
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = formMethods;

  const onSubmit = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();

      data["code"] = data.zip;
      data["zip"] = Number(data.zip);

      try {
        await api.createSite(accessToken, data);
      } catch (e) {
        setError("root.serverError", {
          type: "500",
        });
      }
    },
    [getAccessTokenSilently, setError]
  );

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(onSubmit)}>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={trigger}
      >
        <Modal.Header>Add a new site</Modal.Header>

        <Modal.Content>
          <Form
            success={isSubmitSuccessful}
            loading={isSubmitting}
            error={errors.root}
          >
            <FormInput
              name="imagePath"
              label="Image path"
              placeholder="https://example.com/image.jpg"
              icon="image"
              iconPosition="left"
              required={false}
            />

            <FormSelect
              name="type"
              label="Type"
              placeholder="Select a site type"
              options={SITE_TYPES.map((siteType) => ({
                key: siteType,
                text: siteType,
                value: siteType,
              }))}
            />

            <ServicesAvailable
              chargerSelected={chargerSelected}
              setChargerSelected={setChargerSelected}
            />

            {chargerSelected && (
              <>
                <FormInput
                  name="maxChargerBookingDuration"
                  label="Maximum charger booking duration"
                  placeholder="180"
                  type="number"
                />

                <FormCheckbox name="monitorIdling" label="Monitor idling" />
              </>
            )}

            <Header>Name</Header>

            <Form.Group widths="equal">
              <FormInput name="name" label="Site name" placeholder="Name" />
              <FormInput name="displayName" label="Display name" />
            </Form.Group>

            <Header>Location</Header>

            <Form.Group widths="equal">
              <FormInput name="address1" label="Address 1" />
              <FormInput name="address2" label="Address 2" required={false} />

              <FormInput
                name="zip"
                label="ZIP code"
                placeholder="####"
                type="number"
                width={6}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <FormSelect
                name="timeZoneId"
                label="Time zone"
                placeholder="Time zone"
                options={TIME_ZONES.map((timeZone) => ({
                  key: timeZone,
                  text: timeZone,
                  value: timeZone,
                }))}
              />

              <FormInput name="latitude" label="Latitude" type="number" />
              <FormInput name="longitude" label="Longitude" type="number" />
            </Form.Group>

            <Header>Business hours</Header>
            <Form.Group widths="equal">
              <FormInput
                name="businessHoursStart"
                label="Start"
                placeholder="09:00"
                type="time"
              />
              <FormInput
                name="businessHoursEnd"
                label="End"
                placeholder="16:00"
                type="time"
              />
            </Form.Group>

            <FormCheckbox
              name="freeDuringBusinessHours"
              label="Free during business hours"
            />

            <Form.Group widths="equal">
              <FormInput
                name="weekendStart"
                label="Weekend start"
                placeholder="16:00"
                type="time"
              />
              <FormInput
                name="weekendEnd"
                label="Weekend end"
                placeholder="09:00"
                type="time"
              />
            </Form.Group>

            <Divider hidden />

            <Message success icon>
              <Icon name="check circle" />
              <Message.Content>
                <Message.Header>Success</Message.Header>
                Successfully added site
              </Message.Content>
            </Message>

            <Message error icon>
              <Icon name="times circle" />
              <Message.Content>
                <Message.Header>Internal server error</Message.Header>
                Failed to add charger
              </Message.Content>
            </Message>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            content="Add site"
            labelPosition="right"
            icon="add"
            positive
          />
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
}

function ServicesAvailable({ chargerSelected, setChargerSelected }) {
  const { control } = useFormContext();

  return (
    <Controller
      name="servicesAvailable"
      control={control}
      render={({
        field: { value, onChange, ref, ...field },
        fieldState: { error },
      }) => (
        <Form.Group inline>
          <label>Services available</label>

          {[
            {
              itemName: "Charger",
              onChange: () => setChargerSelected(!chargerSelected),
            },
            { itemName: "Car" },
            { itemName: "Bike" },
          ].map((s) => {
            const isChecked = value.includes(s.itemName);

            return (
              <Form.Checkbox
                key={s.itemName}
                {...field}
                label={s.itemName}
                // NOTE (Matt): Semantic UI/HTML checkbox expects an empty string for true and undefined for false
                value={isChecked ? "" : undefined}
                onChange={(_, data) => {
                  onChange(
                    data.checked
                      ? (value ?? []).concat(data.label)
                      : (value ?? []).filter((item) => item !== data.label)
                  );

                  if (s.onChange) {
                    s.onChange();
                  }
                }}
                error={error ? true : false}
              />
            );
          })}

          {error && (
            <Label basic color="red" pointing="left">
              {error.message}
            </Label>
          )}
        </Form.Group>
      )}
    />
  );
}
