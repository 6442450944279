import { Header, Label, Button, Grid } from 'semantic-ui-react';
import { api } from '../api';
import { useAuth0 } from '@auth0/auth0-react';

const ChargerControls = ({vehicle, setRender}) => {
    const { getAccessTokenSilently } = useAuth0();

    const handleStartCharging = async () => {
        const accessToken = await getAccessTokenSilently();
        
        let success = await api.startCharging(vehicle.id, accessToken);
            if (success) setRender(Math.random())
    };

    const handleStopCharging = async () => {
        const accessToken = await getAccessTokenSilently();
        
        let success = await api.stopCharging(vehicle.id, accessToken);
            if (success) setRender(Math.random())
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                    <Header as='h3' textAlign='center'>Charger State</Header>
                    <Label size = 'huge' className={vehicle.status}>
                        {vehicle.status}
                    </Label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                 <Button color='green' horizontal size='large' onClick={() => handleStartCharging(vehicle.id)}>
                    Start Charge</Button>
                 <Button color='red' horizontal size='large' onClick={() => handleStopCharging(vehicle.id)}>
                    Stop Charge</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
                <Header as='h5' textAlign='center'>Please wait 5 seconds then refresh the page to see if successful :)</Header>
            </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ChargerControls;