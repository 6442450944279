import { boolean, number, string } from "yup";

const requiredString = string().required();

/**
 * The validation schema that is shared by all products
 * @param {{
 *  productModels: {make: string[]}
 * }} productModels
 */
export const productValidationSchema = (productModels) => ({
  name: requiredString.label("Name"),
  registrationPlate: requiredString.label("Registration plate"),
  make: requiredString.oneOf(Object.keys(productModels)).label("Make"),
  model: requiredString
    .label("Model")
    .when("make", ([make], schema) =>
      make
        ? schema.oneOf(productModels[make])
        : schema.oneOf(Object.values(productModels))
    ),
  hireable: boolean().default(true).label("Hireable"),
  siteId: requiredString.label("Hireable"),
  hireRatePerHour: number()
    .positive()
    .required()
    .label("Hire rate per hour")
    .typeError("Hire rate per hour must be a number"),
});

export const defaultProductValues = {
  name: "",
  registrationPlate: "",
  make: null,
  model: null,
  hireable: true,
  siteId: null,
};
