import { Table, Header } from 'semantic-ui-react';

const SiteAssignmentRequests = ({ requests }) => {
    if (requests.length > 5) requests = requests.slice(0, 5);

    return (
        <>
            <Header as='h3' textAlign='center'>Site Assignment Requests</Header>
            <Table color='blue'>
                <Table.Body>
                    {requests.map(function (request) {
                        return (<Table.Row key={request.id}>
                            <Table.Cell>
                                {request.siteDescription}
                            </Table.Cell>
                        </Table.Row>);
                    })}
                </Table.Body>
            </Table>
        </>
    )
};

export default SiteAssignmentRequests;