import React from "react";
import { default as ReactDateTimePicker } from "react-datetime-picker";
import { Label } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the React DateTime Picker to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 *  label: string
 * }} props
 */
export function DateTimePicker({ name, required, label, ...props }) {
  const { control } = useFormContext();
  const isRequired = required ?? true;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <div className="react-datetime-picker__form-field">
          {label && (
            <label
              basic
              // Semantic UI label styles
              style={{
                display: "block",
                margin: "0 0 .28571429rem 0",
                color: "rgba(0, 0, 0, .87)",
                fontSize: ".92857143em",
                fontWeight: "700",
              }}
            >
              {label}
              <span style={{ color: "#db2828" }}>{isRequired && " *"}</span>
            </label>
          )}

          <ReactDateTimePicker
            {...props}
            {...field}
            disableClock={true}
            format="dd/MM/y h:mm a"
            onChange={(value) => {
              field.onChange(value);

              if (props.onChange) {
                props.onChange(value);
              }
            }}
            value={field.value}
          />
          {error && (
            <Label
              basic
              color="red"
              pointing="above"
              style={{ display: "block", width: "fit-content" }}
            >
              {error.message}
            </Label>
          )}
        </div>
      )}
    />
  );
}
