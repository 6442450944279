import React, { useCallback, useEffect, useState } from "react";
import { Divider, Form } from "semantic-ui-react";
import { FormCheckbox, FormInput, FormSelect } from "../../form";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../../api";
import { isLoading } from "../../../utils/isLoading";

/**
 * The form fields that are shared by the product creation models
 * @param {{
 *  productModels: {make: string[]}
 * }} props
 */
export default function ProductInputs({ productModels }) {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedMake, setSelectedMake] = useState();
  const [sites, setSites] = useState({ state: "loading", value: [] });

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedSites = await api.getSites(accessToken);

    setSites({ state: "success", value: fetchedSites });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Form.Group widths="equal">
        <FormInput name="name" label="Name" />
        <FormInput name="registrationPlate" label="Registration plate" />
      </Form.Group>

      <Form.Group widths="equal">
        <FormSelect
          name="make"
          label="Make"
          options={Object.keys(productModels).map((make) => ({
            key: make,
            text: make,
            value: make,
          }))}
          onChange={(_, d) => setSelectedMake(d.value)}
        />

        <FormSelect
          name="model"
          label="Model"
          options={
            selectedMake &&
            productModels[selectedMake].map((model) => ({
              key: model,
              text: model,
              value: model,
            }))
          }
          disabled={!selectedMake}
        />
      </Form.Group>

      <FormCheckbox name="hireable" label="Hireable" defaultChecked />

      <FormSelect
        name="siteId"
        label="Site"
        options={sites.value.map((site) => ({
          key: site.id,
          text: site.name,
          value: site.id,
          description: site.address1,
        }))}
        loading={isLoading(sites)}
      />

      <Divider hidden />
    </>
  );
}
