import React from "react";
import { Button, Dropdown } from "semantic-ui-react";
import CreateVehicleModal from "./CreateVehicleModal";
import CreateChargerModal from "./CreateChargerModal";
import CreateBikeModal from "./CreateBikeModal";

export default function CreateProductDropdown() {
  return (
    <Dropdown
      as={Button}
      button
      color="blue"
      floated="right"
      text="Add new product"
    >
      <Dropdown.Menu>
        <CreateVehicleModal trigger={<Dropdown.Item text="Vehicle" />} />
        <CreateChargerModal trigger={<Dropdown.Item text="Charger" />} />
        <CreateBikeModal trigger={<Dropdown.Item text="Bike" />} />
      </Dropdown.Menu>
    </Dropdown>
  );
}
