import { useState, useEffect, useCallback } from "react";
import { Table, Icon, Grid, Header, Button } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api/index";
import { useParams, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { Loader } from "../components/Loader";
import { Pagination, getPaginatedItems } from "../components/Pagination";
import { nullableTimeToDate } from "../utils/date";
import { TextInput } from "../components/TextInput";
import { Dropdown } from "../components/Dropdown";
import { isLoading } from "../utils/isLoading";
import { useUserAccess } from "../components/UserAccessManager";
import { shortenPhoneNumber } from "../utils/numbers";
import { openInNewTab } from "../utils/openInNewTab";
import { BookingStatusLabel } from "../components/BookingStatusLabel";
import { CreateBookingModal } from "../components/modals/CreateBookingModal";

const VehicleBookings = () => {
  const [vehicleBookings, setVehicleBookings] = useState({
    state: "loading",
    value: [],
  });
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [vehicleRegistrations, setVehicleRegistrations] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({
    searchValue: "",
    vehicleRegistration: [],
    bookingStatus: [],
    bookingPeriod: { startDate: NaN, endDate: NaN }, // Note (Matt): the value of HTML date input is NaN if a date is not selected
  });

  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { isCommunityManager } = useUserAccess();
  let navigate = useNavigate();

  const applyFilters = useCallback((vehicleBookings, filters) => {
    return vehicleBookings.value.filter((booking) => {
      // Search in customer name, id, or phone number
      const searchMatch =
        filters.searchValue === "" ||
        (booking.customerFirstName + " " + booking.customerLastName)
          .toLowerCase()
          .includes(filters.searchValue.toLowerCase()) ||
        booking.id.toLowerCase().includes(filters.searchValue.toLowerCase()) ||
        booking.customerPhoneNumber
          .toLowerCase()
          .includes(shortenPhoneNumber(filters.searchValue.toLowerCase()));

      // Show all bookings, or only bookings where vehicle registration contains the selected registration
      const registrationMatch =
        filters.vehicleRegistration === "all" ||
        booking.vehicleRegistrationPlate.includes(filters.vehicleRegistration);

      // Show all bookings, or only bookings where booking status contains the selected status
      const statusMatch =
        filters.bookingStatus === "all" ||
        booking.status.includes(filters.bookingStatus);

      // Show all bookings, or only bookings where the booking start and end times are between the selected date range
      const dateMatch =
        isNaN(filters.bookingPeriod.startDate) ||
        isNaN(filters.bookingPeriod.endDate) ||
        (nullableTimeToDate([
          booking.bookingActualStartTime,
          booking.bookingStartTime,
        ]) >= filters.bookingPeriod.startDate &&
          nullableTimeToDate([
            booking.bookingActualEndTime,
            booking.bookingEndTime,
          ]) <= filters.bookingPeriod.endDate);

      return searchMatch && registrationMatch && statusMatch && dateMatch;
    });
  }, []);

  const filterBookings = useCallback(
    (vehicleBookings, filters) => {
      const filteredBookingResults = applyFilters(vehicleBookings, filters);
      setFilteredBookings(filteredBookingResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  const updateFilters = (updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  };

  const getSiteName = () => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    setSiteName(params.get("name"));
  };

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    const vehicleBookings = await api.getVehicleBookings(id, accessToken);

    setVehicleBookings({ state: "success", value: vehicleBookings });

    let regos = [
      ...new Set(
        vehicleBookings.map((booking) => booking.vehicleRegistrationPlate)
      ),
    ];
    setVehicleRegistrations(regos);

    setFilteredBookings(vehicleBookings);

    getSiteName();
  }, [getAccessTokenSilently, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    filterBookings(vehicleBookings, filters);
  }, [filterBookings, filters, vehicleBookings]);

  const handleDateRangeSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const startDate = new Date(data.get("startDate")).setHours(0, 0, 0);
    const endDate = new Date(data.get("endDate")).setHours(0, 0, 0);

    setFilters((prev) => ({
      ...prev,
      bookingPeriod: { startDate: startDate, endDate: endDate },
    }));
  };

  const handleDateRangeReset = () => {
    setFilters((prev) => ({
      ...prev,
      bookingPeriod: { startDate: "", endDate: "" },
    }));
  };

  return (
    <>
      <Header as="h3">{siteName}</Header>

      <Grid stackable columns="equal">
        <Grid.Column>
          <TextInput
            fluid
            placeholder="Booking ID, name or phone"
            onSubmit={(updatedFilter) =>
              updateFilters({ searchValue: updatedFilter })
            }
          />
        </Grid.Column>

        <Grid.Column>
          <form
            onSubmit={handleDateRangeSubmit}
            onReset={handleDateRangeReset}
            className="ui form short"
          >
            <div className="ui labeled action input">
              <input type="date" name="startDate" />
              <p className="ui basic label" style={{ borderRadius: 0 }}>
                -
              </p>
              <input
                type="date"
                name="endDate"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeftColor: "transparent",
                }}
              />
              <button type="reset" className="ui icon button">
                <Icon name="close" />
              </button>
              <button type="submit" className="ui icon button">
                <Icon name="filter" />
              </button>
            </div>
          </form>
        </Grid.Column>

        <Grid.Column>
          <CreateBookingModal
            trigger={
              <Button color="blue" floated="right">
                <Icon name="add" />
                New booking
              </Button>
            }
            defaultSiteId={id}
          />
        </Grid.Column>
      </Grid>

      <Loader isLoading={isLoading(vehicleBookings)}>
        <Table selectable textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="9">Bookings List</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              {!isCommunityManager && (
                <>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                </>
              )}
              <Table.HeaderCell>
                <Dropdown
                  label="Vehicle"
                  items={[
                    { key: "all", text: "All", value: "all" },
                    ...vehicleRegistrations.map((rego) => ({
                      key: rego,
                      text: rego,
                      value: rego,
                    })),
                  ]}
                  onSelect={(vehicleRegistration) =>
                    updateFilters({ vehicleRegistration })
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Start Time</Table.HeaderCell>
              <Table.HeaderCell>End Time</Table.HeaderCell>
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <Table.HeaderCell>
                <Dropdown
                  label="Status"
                  items={[
                    "All",
                    "Approved",
                    "Ongoing",
                    "Ended",
                    "Cancelled",
                    "Expired",
                  ].map((item) => ({
                    key: item,
                    text: item,
                    value: item.toLowerCase(),
                  }))}
                  onSelect={(bookingStatus) => updateFilters({ bookingStatus })}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPaginatedItems(filteredBookings, activePage).map((booking) => (
              <Table.Row
                key={booking.id}
                onClick={() => navigate(`/bookings/${booking.id}`)}
                onMouseUp={(e) => openInNewTab(e, `bookings/${booking.id}`)}
              >
                <Table.Cell>
                  {booking.customerFirstName} {booking.customerLastName}
                </Table.Cell>
                {!isCommunityManager && (
                  <>
                    <Table.Cell>{booking.customerPhoneNumber}</Table.Cell>
                    <Table.Cell>{booking.customerEmail}</Table.Cell>
                  </>
                )}
                <Table.Cell>
                  {booking.vehicleMake} - {booking.vehicleRegistrationPlate}
                </Table.Cell>
                <Table.Cell>
                  <Moment format="llll Z" local>
                    {booking.bookingActualStartTime == null
                      ? booking.bookingStartTime
                      : booking.bookingActualStartTime}
                  </Moment>
                </Table.Cell>
                <Table.Cell>
                  <Moment format="llll Z" local>
                    {booking.bookingActualEndTime == null
                      ? booking.bookingEndTime
                      : booking.bookingActualEndTime}
                  </Moment>
                </Table.Cell>
                <Table.Cell>
                  <Moment
                    format="H[h]:mm[m]"
                    duration={
                      booking.bookingActualStartTime == null
                        ? booking.bookingStartTime
                        : booking.bookingActualStartTime
                    }
                    date={
                      booking.bookingActualEndTime == null
                        ? booking.bookingEndTime
                        : booking.bookingActualEndTime
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <BookingStatusLabel booking={booking} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Grid stackable>
          <Grid.Column width={4}>
            <Pagination
              activePage={activePage}
              onPageChange={(page) => setActivePage(page)}
              itemQuantity={filteredBookings.length}
            />
          </Grid.Column>
        </Grid>
      </Loader>
    </>
  );
};

export default VehicleBookings;
