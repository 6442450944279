import React from "react";
import { FormInput as SUIFormInput } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the Semantic UI input component to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 *  label: string
 *  placeholder: string
 * }} props
 */
export function FormInput({ name, required, label, placeholder, ...props }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ?? true,
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <SUIFormInput
          {...props}
          {...field}
          // NOTE (Matt): Stop propagation to prevent keys typed in the input from
          // triggering events in any parent components
          onKeyDown={(e) => e.stopPropagation()}
          label={label}
          placeholder={placeholder ?? label}
          required={required ?? true}
          input={<input data-1p-ignore />}
          error={
            error && {
              content: error.message,
              pointing: "above",
            }
          }
        />
      )}
    />
  );
}
