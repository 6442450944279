import config from '../config';
import { createContext, useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const COMMUNITY_MANAGER_ROLE = 'community-manager';

const UserAccessContext = createContext({roles: []});

export const UserAccessManager = ({children}) => {
  const adminUser = useAuth0().user;
  const userRoles = useMemo(() => (
    adminUser ? adminUser[`${config.auth0.NAMESPACE}/roles`] : []
  ), [adminUser]);

  return (
    <UserAccessContext.Provider value={{roles: userRoles}}>
      {children}
    </UserAccessContext.Provider>
  )
}

export function useUserAccess() {
  const {roles} = useContext(UserAccessContext);

  return {
    isCommunityManager: roles.includes(COMMUNITY_MANAGER_ROLE),
    roles
  }
}
