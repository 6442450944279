import { Icon, Label } from "semantic-ui-react";
import Moment from "react-moment";

/**
 * Displays the status of a booking.\
 * If the booking is overdue, an overdue label is displayed\
 * with optional text indicating how long the booking has been overdue for.
 * @param {{
 *  booking: {
 *    bookingEndTime: string
 *    status: string
 *  }
 * }} props
 * @returns
 */
export const BookingStatusLabel = ({ booking, displayTimeOverdue }) => {
  var endTime = new Date(booking.bookingEndTime);

  if (new Date() > endTime && booking.status === "ongoing") {
    return (
      <>
        <Label color="red">
          {displayTimeOverdue && <Icon name="clock" />}
          overdue
        </Label>

        {displayTimeOverdue && (
          <p>
            by{" "}
            <Moment
              format="h[h] m[m]"
              trim
              duration={endTime}
              date={new Date()}
            />
          </p>
        )}
      </>
    );
  } else {
    return <Label className={booking.status}>{booking.status}</Label>;
  }
};
