import { useOAuth2 } from "@tasoskakour/react-use-oauth2";

import { useEffect, useState} from 'react';
import { Button, Loader, Header, Icon, Segment, Grid, Image} from "semantic-ui-react";

import config from '../config';

const OAuth2 = () => {
    const [name, setName] = useState('')

    useEffect(() => {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        setName(params.get('name'));
    }, []);

    const { data, loading, error, getAuth, logout } = useOAuth2({
        authorizeUrl: "https://auth.tesla.com/oauth2/v3/authorize",
        clientId: config.oauth2.CLIENTID,
        redirectUri: config.oauth2.REDIRECTURI,
        scope: "openid offline_access user_data vehicle_device_data vehicle_cmds vehicle_charging_cmds",
        responseType: "code",
        exchangeCodeForTokenServerURL:`${config.oauth2.TOKENURL}/${name}`,
        exchangeCodeForTokenMethod: "POST",
        onSuccess: (payload) => console.log("Success", payload),
        onError: (error_) => console.log("Error", error_)
    })
   
    const isLoggedIn = Boolean(data === 'ok');
    const teslaError = Boolean(data?.errors);

    if (loading) {
        return <Loader active size='big' />
    }

    if (isLoggedIn) {
        return (
            <Grid centered>
                <Grid.Column width={8}>
                <Image src='/ohmie-go-logo-dark.jpg' size='medium' centered/>
                    <Segment padded textAlign="center">
                        
                        <Icon color='green' size='huge' name='check circle' />
                        <Header as='h2' >
                            Authorisation succesful!
                        </Header>
                        You may now close this page.
                    </Segment>
                    <Button floated="right" icon='redo' labelPosition='right' content='Try again' onClick={logout} />
                </Grid.Column>
            </Grid>
        )
    }

    if (error || teslaError) {
        return (
            <Grid centered>
                <Grid.Column width={8}>
                <Image src='/ohmie-go-logo-dark.jpg' size='medium' centered/>
                    <Segment padded textAlign="center">
                        <Icon color='red' size='huge' name='close' />
                        <Header as='h2' >
                            Error - authorisation unsuccessful
                        </Header>

                        Contact Ohmie GO.
                        <div>Error: {data?.errors} </div>
                    </Segment>
                    <Button floated="right" icon='redo' labelPosition='right' content='Try again' onClick={logout} />
                </Grid.Column>
            </Grid>
        )
    }

    return (
        <>
            <Grid centered>
                <Grid.Column width={8}>
                    <Image src='/ohmie-go-logo-dark.jpg' size='medium' centered/>
                    <Segment padded compact textAlign="center">
                        <Header as='h1' > Hello, {name}! </Header>
                        <Header as='h2'> Authorise Ohmie GO to have access to your Tesla fleet</Header>
                        <Button content='Authorise' color='blue' icon='unlock alternate' labelPosition='left' onClick={() => getAuth()} />
                    </Segment>
                </Grid.Column>
            </Grid>
        </>
    )
}

export default OAuth2;