import {
  Table,
  Label,
  Popup,
  Button,
  Icon,
  Dropdown,
  Header,
} from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUserAccess } from "../components/UserAccessManager";
import IssuesModal from "./modals/IssuesModal";

const UserDetails = ({ user }) => {
  const [issues, setIssues] = useState([]);
  const [isAdminIssueModalOpen, setIsAdminIssueModalOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { isCommunityManager } = useUserAccess();

  const helpScoutNewTicketLink = useMemo(
    () =>
      `https://secure.helpscout.net/mailbox/2ca1deda3521ef0a/new-ticket/?name=${user.firstName}+${user.lastName}&email=${user.email}`,
    [user.email, user.firstName, user.lastName]
  );

  const fetchData = useCallback(async () => {
    if (isCommunityManager) return;

    const accessToken = await getAccessTokenSilently();
    const fetchedIssues = await api.getUserAdminIssues(user.id, accessToken);
    setIssues(fetchedIssues);
  }, [getAccessTokenSilently, isCommunityManager, user.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenIssueModal = useCallback(() => {
    setIsAdminIssueModalOpen(true);
  }, []);

  const handleCloseIssueModal = useCallback(() => {
    setIsAdminIssueModalOpen(false);
  }, []);

  const isUserSuspended = useMemo(() => {
    return issues.some((issue) => issue.status === "suspension");
  }, [issues]);

  const getAccountType = useCallback((authId) => {
    const accountType = authId.split("|")[0];

    switch (accountType) {
      case "auth0":
        return "Username Password";
      case "google-oauth2":
        return "Google";
      case "apple":
        return "Apple";
      default:
        return "Click for full Auth0 ID";
    }
  }, []);

  return (
    <>
      <Table color="blue" definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>First Name</Table.Cell>

            <Table.Cell>
              {isUserSuspended && (
                <Popup
                  on="hover"
                  content="This user is suspended or banned."
                  offset={[-7, 0]} // NOTE (Matt): Without this, the pointer is not centred above the icon due to the icon's margin
                  trigger={
                    <Icon color="red" name="exclamation circle" size="large" />
                  }
                />
              )}
              {user.firstName}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell collapsing>Last Name</Table.Cell>
            <Table.Cell>{user.lastName}</Table.Cell>
          </Table.Row>

          {!isCommunityManager && (
            <>
              <Table.Row>
                <Table.Cell collapsing>Phone Number</Table.Cell>
                <Table.Cell>{user.phoneNumber}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>Email</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
              </Table.Row>

              {typeof user.authId !== "undefined" && (
                <Table.Row>
                  <Table.Cell collapsing>Auth0</Table.Cell>
                  <Popup
                    on="click"
                    pinned
                    position="bottom left"
                    content={user.authId}
                    trigger={
                      <Table.Cell>{getAccountType(user.authId)} </Table.Cell>
                    }
                  />
                </Table.Row>
              )}

              <Table.Row>
                <Table.Cell collapsing>Credit Balance</Table.Cell>
                <Table.Cell>${user.creditBalance}</Table.Cell>
              </Table.Row>
            </>
          )}
          <Table.Row>
            <Table.Cell collapsing>Services</Table.Cell>

            <Table.Cell>
              {user.requestedServicesList.map((service) => (
                <Label size="medium" key={service}>
                  {service}
                </Label>
              ))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Button
        as="div"
        labelPosition="right"
        onClick={() => handleOpenIssueModal()}
      >
        <Button color="blue" compact size="small">
          <Icon name="warning sign" />
          User notes
        </Button>

        <Label as="a" basic color="blue" pointing="left">
          {issues.length}
        </Label>
      </Button>

      {!isCommunityManager && (
        <>
          <Header as="h4">Contact user</Header>

          <Button.Group
            compact
            size="small"
            basic
            // NOTE (Matt): Without this, there is no gap between this button and the other buttons on the rest of the page
            style={{ marginRight: "4px" }}
          >
            <Button
              href={`${helpScoutNewTicketLink}&subject=International+licence+verification`}
              target="_blank"
            >
              <Icon name="mail" />
              Email
            </Button>
            <Dropdown className="button icon" trigger={<></>}>
              <Dropdown.Menu>
                <Dropdown.Header
                  content="Subject"
                  // NOTE (Matt): This is a hack to make the text a more readable size.
                  // The value was taken from Semantic UI's styles.
                  style={{ fontSize: ".78571429rem" }}
                />
                <Dropdown.Divider />

                <Dropdown.Item
                  content="More info required"
                  href={`${helpScoutNewTicketLink}&subject=More+information+required`}
                  target="_blank"
                />

                <Dropdown.Item
                  content="International licence"
                  href={`${helpScoutNewTicketLink}&subject=International+licence+verification`}
                  target="_blank"
                />

                <Dropdown.Divider />

                <Dropdown.Item
                  icon="search"
                  content="Search email"
                  href={`https://secure.helpscout.net/search/?query=${user.email}`}
                  target="_blank"
                />
              </Dropdown.Menu>
            </Dropdown>
          </Button.Group>
        </>
      )}

      <IssuesModal
        isOpen={isAdminIssueModalOpen}
        onClose={handleCloseIssueModal}
        userId={user.id}
        issues={issues}
        onSave={() => fetchData()}
      />
    </>
  );
};

export default UserDetails;
