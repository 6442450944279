import { Table, Header, Form, Input, Label, Button } from "semantic-ui-react";
import { api } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const UserCoupons = ({ user }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [allCoupons, setAllCoupons] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const accessToken = await getAccessTokenSilently();

      const fetchedCoupons = await api.getUsersCoupons(user.id, accessToken);
      setAllCoupons(fetchedCoupons);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    const accessToken = await getAccessTokenSilently();
    const amount = parseInt(event.target.amount.value);
    if (isNaN(amount)) return;
    const coupon = await api.generateCouponForUser(user, amount, accessToken);
    setAllCoupons([coupon, ...allCoupons]);
  };

  return (
    <>
      <Header as="h3" textAlign="center">
        Coupons
      </Header>

      <Form onSubmit={handleSubmit}>
        <Form.Group inline widths="equal">
          <Form.Field width={8}>
            <Input
              labelPosition="left"
              type="number"
              placeholder="Amount"
              name="amount"
            >
              <Label basic>$</Label>
              <input />
            </Input>
          </Form.Field>

          <Button type="submit" floated="right">
            Generate Coupon!
          </Button>
        </Form.Group>
      </Form>

      <div style={{ height: "350px", overflow: "auto" }}>
        <Table color="green">
          <Table.Header>
            <Table.Row>
              {["Code", "Value", "Expiry", "Status"].map((c) => (
                <Table.HeaderCell key={c}>{c}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {allCoupons.map((coupon) => {
              var options = { hourCycle: "h24", timeZoneName: "shortOffset" };
              var formattedDate = new Date(coupon.expiryDate).toLocaleString(
                "en-AU",
                options
              );

              return (
                <Table.Row key={coupon.id}>
                  <Table.Cell>{coupon.code}</Table.Cell>
                  <Table.Cell>${coupon.value}</Table.Cell>
                  <Table.Cell>{formattedDate}</Table.Cell>
                  <Table.Cell>{coupon.status}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default UserCoupons;
