import {
  Table,
  Grid,
  Label,
  Header,
  List,
  Button,
  Message,
  Icon,
  Form,
  Divider,
} from "semantic-ui-react";
import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import { useNavigate } from "react-router-dom";
import { openInNewTab } from "../utils/openInNewTab";
import { getPaginatedItems, Pagination } from "../components/Pagination";
import { BookingStatusLabel } from "../components/BookingStatusLabel";
import Moment from "react-moment";
import { zipPrefixMap } from "../utils/location";
import { DateTimePicker, FormInput, FormSelect } from "../components/form";
import { Dropdown } from "../components/Dropdown";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { date, object, string } from "yup";
import { isLoading } from "../utils/isLoading";
import { Loader } from "../components/Loader";
import { CreateBookingModal } from "../components/modals/CreateBookingModal";

const PRODUCT_TYPES = ["Vehicle", "Bike", "Charger"];
const BOOKING_STATUSES = [
  "approved",
  "ongoing",
  "ended",
  "cancelled",
  "expired",
];

const BOOKING_STATUS_OPTIONS = BOOKING_STATUSES.map((status) => ({
  key: status,
  text: <Label className={status}>{status}</Label>,
  value: status,
}));

const FILTER_DISPLAY_NAMES = {
  siteName: "Site",
  bookingStatus: "Status",
};

const DEFAULT_FILTER_VALUES = {
  siteName: "all",
  bookingStatus: "all",
};

const optionalString = string().notRequired();

const validationSchema = object({
  productType: optionalString,
  productRegistrationPlate: optionalString,
  productEtagNumber: optionalString,
  bookingTime: date().notRequired(),
  bookingStatus: optionalString,
  userName: optionalString,
  initialZipDigit: optionalString,
  siteId: optionalString,
});

const Bookings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [bookings, setBookings] = useState({ state: "loading", value: [] });
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [siteNames, setSiteNames] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({
    ...DEFAULT_FILTER_VALUES,
  });

  const formMethods = useForm({
    defaultValues: {
      productType: null,
      productRegistrationPlate: "",
      productEtagNumber: "",
      bookingTime: null,
      bookingStatus: null,
      userName: "",
      initialZipDigit: null,
      siteId: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = formMethods;

  const applyFilters = useCallback((bookings, filters) => {
    return bookings.value.filter((booking) => {
      // Show all sites, or only sites where the site name is the selected site name
      const siteMatch =
        filters.siteName === "all" || booking.siteName === filters.siteName;

      // Show all bookings, or only bookings where booking status contains the selected status
      const statusMatch =
        filters.bookingStatus === "all" ||
        booking.status === filters.bookingStatus;

      return siteMatch && statusMatch;
    });
  }, []);

  const filterBookings = useCallback(
    (bookings, filters) => {
      const filteredBookingResults = applyFilters(bookings, filters);
      setFilteredBookings(filteredBookingResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  const updateFilters = useCallback((updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  }, []);

  const clearFilters = useCallback(() => {
    setFilters({ ...DEFAULT_FILTER_VALUES });
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();

      const requestObject = {
        productType: data.productType,
        productRegistrationPlate: data.productRegistrationPlate,
        productEtagNumber: data.productEtagNumber,
        bookingTime: data.bookingTime,
        bookingStatus: data.bookingStatus,
        userName: data.userName,
        initialZipDigit: data.initialZipDigit,
        siteId: data.siteId,
      };

      try {
        const fetchedBookings = await api.getMatchingBookings(
          accessToken,
          requestObject
        );
        setBookings({ state: "success", value: fetchedBookings });

        const sites = [
          ...new Set(fetchedBookings.map((booking) => booking.siteName)),
        ];
        setSiteNames(sites);
      } catch {
        setError("root.serverError", {
          type: "500",
          message: "API error. Contact Ike.",
        });
      }
    },
    [getAccessTokenSilently, setError]
  );

  useEffect(() => {
    filterBookings(bookings, filters);
  }, [bookings, filterBookings, filters]);

  return (
    <Grid stackable columns="equal" verticalAlign="middle">
      <Grid.Row>
        <Grid.Column>
          <Header>Bookings</Header>
        </Grid.Column>

        <Grid.Column>
          <CreateBookingModal
            trigger={
              <Button color="blue" floated="right">
                <Icon name="add" />
                New booking
              </Button>
            }
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <BookingForm
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isLoading={isLoading}
            formMethods={formMethods}
            isSubmitting={isSubmitting}
            errors={errors}
          />
        </Grid.Column>
      </Grid.Row>

      <Divider hidden />

      <Grid.Row>
        <Grid.Column>
          <Loader isLoading={isSubmitting}>
            {isSubmitSuccessful && (
              <BookingTable
                onUpdateFilters={updateFilters}
                onNavigate={navigate}
                setActivePage={setActivePage}
                onClearFilters={clearFilters}
                filteredBookings={filteredBookings}
                siteNames={siteNames}
                activePage={activePage}
                filters={filters}
              />
            )}
          </Loader>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

/**
 * A form that returns bookings that match the input values.
 * @param {{
 *  handleSubmit: () => {}
 *  onSubmit: () => {}
 *  isLoading: () => {}
 *  formMethods: {
 *    handleSubmit: () => {}
 *    setError: () => {}
 *    formState: { isSubmitting: boolean, isSubmitSuccessful: boolean, errors: {} }
 *  }
 *  isSubmitting: boolean
 *  errors: { root: { serverError: { message: string }}}
 * }} props
 */
const BookingForm = ({
  handleSubmit,
  onSubmit,
  isLoading,
  formMethods,
  isSubmitting,
  errors,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [sites, setSites] = useState({ state: "loading", value: [] });
  const [selectedZipPrefix, setSelectedZipPrefix] = useState("all");

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedSites = await api.getSites(accessToken);

    setSites({ state: "success", value: fetchedSites });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(onSubmit)}>
      <Form error={errors.root !== undefined}>
        <Form.Group widths="equal">
          <FormSelect
            clearable
            required={false}
            search={false}
            name="productType"
            label="Product type"
            options={PRODUCT_TYPES.map((type) => ({
              key: type,
              text: type,
              value: type,
            }))}
          />

          <FormInput
            required={false}
            name="productRegistrationPlate"
            label="Product registration"
          />

          <FormInput
            required={false}
            name="productEtagNumber"
            label="Product etag number"
          />
        </Form.Group>

        <Form.Group widths="equal">
          <DateTimePicker required={false} name="bookingTime" label="Time" />

          <FormSelect
            clearable
            required={false}
            search={false}
            name="bookingStatus"
            label="Status"
            options={BOOKING_STATUS_OPTIONS}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field width="6">
            <FormInput required={false} name="userName" label="User name" />
          </Form.Field>

          <Form.Field width="3">
            <FormSelect
              clearable
              required={false}
              search={false}
              name="initialZipDigit"
              label="State"
              options={Object.keys(zipPrefixMap).map((state) => ({
                key: state,
                text: state,
                value: zipPrefixMap[state],
              }))}
              onChange={(_, { value }) =>
                value === ""
                  ? setSelectedZipPrefix("all")
                  : setSelectedZipPrefix(value)
              }
            />
          </Form.Field>

          <Form.Field width="7">
            <FormSelect
              clearable
              required={false}
              name="siteId"
              label="Site"
              options={sites.value
                .filter(
                  (site) =>
                    selectedZipPrefix === "all" ||
                    site.zip.toString()[0] === selectedZipPrefix
                )
                .map((site) => ({
                  key: site.id,
                  text: site.name,
                  value: site.id,
                  description: site.address1,
                }))}
              loading={isLoading(sites)}
            />
          </Form.Field>
        </Form.Group>

        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          content="Find booking"
          labelPosition="left"
          icon="search"
          positive
          disabled={isSubmitting}
        />

        <Message error icon>
          <Icon name="times circle" />

          <Message.Content>
            <Message.Header>Something went wrong</Message.Header>
            {errors.root?.serverError?.message}
          </Message.Content>
        </Message>
      </Form>
    </FormProvider>
  );
};

/**
 * A table that displays fetched bookings.
 * @param {{
 *  onUpdateFilters: () => {}
 *  onNavigate: () => {}
 *  setActivePage: () => {}
 *  onClearFilters: () => {}
 *  siteNames: string[]
 *  activePage: number
 *  filters: { siteName: string, bookingStatus: string }
 *  filteredBookings: [{
 *    id: string,
 *    siteName: string,
 *    customerFirstName: string,
 *    customerLastName: string,
 *    vehicleMake: string,
 *    vehicleRegistrationPlate: string,
 *    bookingStartTime: string,
 *    bookingEndTime: string,
 *    bookingActualStartTime: string,
 *    bookingActualEndTime: string
 *  }]
 * }} props
 */
const BookingTable = ({
  onUpdateFilters,
  onNavigate,
  setActivePage,
  onClearFilters,
  siteNames,
  activePage,
  filters,
  filteredBookings,
}) => (
  <>
    <List horizontal>
      <List.Item>
        <List.Header>Applied filters</List.Header>
      </List.Item>

      {Object.keys(filters).map(
        (key) =>
          filters[key] !== DEFAULT_FILTER_VALUES[key] && (
            <List.Item key={key}>
              <Label
                content={FILTER_DISPLAY_NAMES[key]}
                detail={filters[key]}
                onRemove={() =>
                  onUpdateFilters({
                    [key]: DEFAULT_FILTER_VALUES[key],
                  })
                }
              />
            </List.Item>
          )
      )}

      <List.Item>
        <Button size="tiny" basic compact onClick={onClearFilters}>
          Clear filters
        </Button>
      </List.Item>
    </List>

    <Table selectable={filteredBookings.length !== 0} textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Dropdown
              label="Site"
              scrolling
              floating
              items={[
                { key: "all", text: "All", value: "all" },
                ...siteNames.map((siteName) => ({
                  key: siteName,
                  text: siteName,
                  value: siteName,
                })),
              ]}
              onSelect={(siteName) => onUpdateFilters({ siteName })}
            />
          </Table.HeaderCell>

          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Vehicle</Table.HeaderCell>
          <Table.HeaderCell>Start Time</Table.HeaderCell>
          <Table.HeaderCell>End Time</Table.HeaderCell>
          <Table.HeaderCell>Duration</Table.HeaderCell>

          <Table.HeaderCell>
            <Dropdown
              label="Status"
              floating
              items={[
                { key: "all", text: "All", value: "all" },
                ...BOOKING_STATUSES.map((status) => ({
                  key: status,
                  text: status,
                  value: status.toLowerCase(),
                })),
              ]}
              onSelect={(bookingStatus) => onUpdateFilters({ bookingStatus })}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {filteredBookings.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={7}>No results</Table.Cell>
          </Table.Row>
        ) : (
          getPaginatedItems(filteredBookings, activePage).map((booking) => (
            <Table.Row
              key={booking.id}
              onClick={() => onNavigate(`/bookings/${booking.id}`)}
              onMouseUp={(e) => openInNewTab(e, `bookings/${booking.id}`)}
            >
              <Table.Cell>{booking.siteName}</Table.Cell>

              <Table.Cell>
                {booking.customerFirstName} {booking.customerLastName}
              </Table.Cell>

              <Table.Cell>
                {booking.vehicleMake} - {booking.vehicleRegistrationPlate}
              </Table.Cell>

              <Table.Cell>
                <Moment format="llll Z" local>
                  {booking.bookingActualStartTime == null
                    ? booking.bookingStartTime
                    : booking.bookingActualStartTime}
                </Moment>
              </Table.Cell>

              <Table.Cell>
                <Moment format="llll Z" local>
                  {booking.bookingActualEndTime == null
                    ? booking.bookingEndTime
                    : booking.bookingActualEndTime}
                </Moment>
              </Table.Cell>

              <Table.Cell>
                <Moment
                  format="H[h]:mm[m]"
                  duration={
                    booking.bookingActualStartTime == null
                      ? booking.bookingStartTime
                      : booking.bookingActualStartTime
                  }
                  date={
                    booking.bookingActualEndTime == null
                      ? booking.bookingEndTime
                      : booking.bookingActualEndTime
                  }
                />
              </Table.Cell>

              <Table.Cell>
                <BookingStatusLabel booking={booking} />
              </Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>

    <Grid stackable>
      <Grid.Column>
        <Pagination
          activePage={activePage}
          onPageChange={(page) => setActivePage(page)}
          itemQuantity={filteredBookings.length}
        />
      </Grid.Column>
    </Grid>
  </>
);

export default Bookings;
