import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Button, Label, Transition } from "semantic-ui-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDamageReports } from "./DamageReportsContext";

const Navbar = () => {
  const [activeItem, setActiveItem] = useState("home");

  const { logout, isAuthenticated } = useAuth0();
  const { isLoading, badgeCount } = useDamageReports();

  const handleClick = (_, { name }) => {
    setActiveItem(name);
  };

  const LogoutButton = () => {
    return (
      <Button
        color="google plus"
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Log out
      </Button>
    );
  };

  return (
    <>
      <Menu color="teal" inverted size="large">
        {[
          {
            itemName: "Home",
            content: (
              <img
                src="/ohmie-logo.ico"
                alt="ohmie-logo"
                style={{ width: 30 }}
              />
            ),
            to: "/",
          },
          { itemName: "Bookings" },
          { itemName: "Users" },
          { itemName: "Sites" },
          { itemName: "Vehicles", content: "Products" },
          {
            itemName: "Damages",
            content: (
              <>
                Damages
                <Transition animation="bounce" visible={isLoading}>
                  {badgeCount === 0 ? (
                    <></>
                  ) : (
                    <Label color="red" circular size="small">
                      {badgeCount}
                    </Label>
                  )}
                </Transition>
              </>
            ),
          },
        ].map((item) => (
          <Menu.Item
            key={item.itemName}
            name={item.itemName}
            active={activeItem === item.itemName}
            onClick={handleClick}
            as={Link}
            to={item.to ?? `/${item.itemName.toLowerCase()}`}
          >
            {item.content ?? item.itemName}
          </Menu.Item>
        ))}

        {isAuthenticated && (
          <Menu.Menu position="right">
            <Menu.Item position="right" as={LogoutButton} />
          </Menu.Menu>
        )}
      </Menu>
    </>
  );
};

export default Navbar;
