import {
  Header,
  Grid,
  Image,
  Segment,
  Card,
  Icon,
  Label,
  Divider,
  Button,
  Message,
  Form,
  Container,
  FormButton,
  Checkbox,
  Loader,
} from "semantic-ui-react";
import Moment from "react-moment";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from "react-router-dom";
import { api } from "../api";
import { useDamageReports } from "../components/DamageReportsContext";
import { useUserAccess } from "../components/UserAccessManager";

const DamageReport = () => {
  const { id } = useParams();
  const [damage, setDamage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState({});
  const [render, setRender] = useState(Math.random());
  const [editing, setEditing] = useState(false);
  const [newDamageDescription, setNewDamageDescription] = useState("");
  const [damageImages, setDamageImages] = useState({});

  const navigate = useNavigate();
  const { updateDamageReport } = useDamageReports();
  const { isCommunityManager } = useUserAccess();
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    const accessToken = await getAccessTokenSilently();

    const fetchedDamage = await api.getDamageReportById(id, accessToken);
    setDamage(fetchedDamage);
    setDamageImages(fetchedDamage.images);
    setNewDamageDescription(fetchedDamage.description);
    setIsLoading(false);
  };

  const handleToggleEditMode = () => {
    setEditing(!editing);
  };

  const handleUpdateDamageReportType = (newType) => {
    updateDamageReport(damage.id, newType);
    damage.type = newType;
    setDamage(damage);
  };

  const handleArchiveDamageReport = async (damageReportId) => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      type: "archived",
    };

    await api
      .patchDamageReport(damageReportId, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        damage.type = "archived";
        setDamage(damage);
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });
  };

  const handleSetNewThumbnailImage = async (targetImage) => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      isThumbnail: true,
    };

    await api
      .patchDamageImage(targetImage.id, accessToken, requestObject)
      .then(() => {
        setDamageImages(
          damageImages.map((image) =>
            image.id === targetImage.id
              ? { ...image, isThumbnail: true }
              : { ...image, isThumbnail: false }
          )
        );
        setSaveStatus({ status: "success", errorMessage: "" });
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });
  };

  const handleDamageDescriptionChange = (e) => {
    setNewDamageDescription(e.target.value);
  };

  const handleFormSubmit = async () => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      description: newDamageDescription,
    };

    await api
      .patchDamageReport(damage.id, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        setRender(Math.random());
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });

    setEditing(false);
  };

  const getMiniBookingId = (id) => {
    return id.split("-")[0];
  };

  useEffect(() => {
    fetchData();
  }, [render]);

  if (isLoading) {
    return <Loader active size="big" />;
  }

  return (
    <Grid centered stackable>
      <Grid.Row>
        <Header as="h1">Damage Report</Header>
      </Grid.Row>
      <Grid.Row stretched>
        {saveStatus.status === "success" && (
          <Message positive>
            <Message.Header>Save was Successful!</Message.Header>
          </Message>
        )}
        {saveStatus.status === "error" && (
          <Message negative>
            <Message.Header>
              Something went wrong - {saveStatus.errorMessage}
            </Message.Header>
          </Message>
        )}
      </Grid.Row>
      <Grid.Column stretched width={8}>
        <Segment>
          <Header as="h3">
            <Moment format="LLLL Z" local>
              {damage.createdAt}
            </Moment>
          </Header>
          <Label className={damage.type} attached="top right" size="large">
            {damage.type}
          </Label>
          <Grid stackable columns={2}>
            <Grid.Column width={14}>
              {editing === false ? (
                <p>{damage.description}</p>
              ) : (
                <Form onSubmit={handleFormSubmit}>
                  <textarea
                    label="Description"
                    value={newDamageDescription}
                    rows={5}
                    onChange={(e) => handleDamageDescriptionChange(e)}
                  />
                  <Container textAlign="right">
                    <FormButton
                      size="small"
                      color="green"
                      icon="check"
                      labelPosition="left"
                      content="Save"
                    />
                  </Container>
                </Form>
              )}
            </Grid.Column>
            {!isCommunityManager && (
              <Grid.Column width={2}>
                <Button icon onClick={() => handleToggleEditMode()}>
                  {editing ? <Icon name="close" /> : <Icon name="edit" />}
                </Button>
              </Grid.Column>
            )}
          </Grid>
        </Segment>
      </Grid.Column>
      <Grid.Column width={4}>
        <Card.Group itemsPerRow={1}>
          <Card
            onClick={() => navigate(`../vehicles/${damage.vehicleId}`)}
            header={
              <Header>
                <Icon name="car" />
                {damage.vehicleRegistrationPlate}
              </Header>
            }
            meta={`${damage.vehicleMake} ${damage.vehicleModel} `}
            description={damage.bookingSiteName}
            icon="car"
          />
          <Card
            onClick={() => navigate(`../bookings/${damage.bookingId}`)}
            header={
              <Header>
                <Icon name="calendar" />
                Booking - {getMiniBookingId(damage.bookingId)}
              </Header>
            }
            description={
              <>
                <p>
                  <Moment format="llll Z" local>
                    {damage.bookingStartTime}
                  </Moment>
                </p>
                <p>
                  <Moment format="llll Z" local>
                    {damage.bookingEndTime}
                  </Moment>
                </p>
              </>
            }
          />
        </Card.Group>
      </Grid.Column>
      {!isCommunityManager && (
        <Grid.Row centered verticalAlign="bottom">
          <Button
            basic
            color="red"
            content="Discard"
            icon="close"
            disabled={damage.type !== "found"}
            onClick={() => handleUpdateDamageReportType("discarded")}
          />
          <Button
            basic
            color="green"
            content="Verify"
            icon="check"
            disabled={damage.type === "archived"}
            onClick={() => handleUpdateDamageReportType("verified")}
          />
          <Button
            basic
            color="blue"
            content="Archive"
            icon="archive"
            disabled={damage.type !== "verified"}
            onClick={() => handleArchiveDamageReport(damage.id)}
          />
        </Grid.Row>
      )}
      &nbsp;
      <Grid.Row>
        <Header as="h2">Images</Header>
        <Divider horizontal />
      </Grid.Row>
      <Grid.Row>
        <Image.Group size="big">
          {damageImages.map((image) => (
            <div key={image.pathToImage}>
              <div>
                {image.isThumbnail ? (
                  <Segment color="yellow" textAlign="right" attached="top">
                    <Checkbox
                      checked={image.isThumbnail}
                      label="Set as thumbnail"
                      onChange={() => handleSetNewThumbnailImage(image)}
                    />
                  </Segment>
                ) : (
                  <Segment textAlign="right" attached="top">
                    <Checkbox
                      checked={image.isThumbnail}
                      label="Set as thumbnail"
                      onChange={() => handleSetNewThumbnailImage(image)}
                    />
                  </Segment>
                )}
                <Image
                  src={image.pathToImage}
                  href={`${image.pathToImage}`}
                  target="_blank"
                ></Image>
              </div>
              &nbsp;
            </div>
          ))}
        </Image.Group>
      </Grid.Row>
    </Grid>
  );
};

export default DamageReport;
